<template>
  <div class="back-btn" @click="router.push({ name: 'settings-reporting-templates-all' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to Reporting
  </div>

  <div
    class="settings-title"
    style="display: flex; justify-content: space-between; align-items: flex-end"
  >
    Edit Report Template

    <Tooltip
      content="Toggle whether this template is able to be used when reporting"
      placement="left"
    >
      <ToggleSwitch
        v-model="reportTemplate.enabled"
        @update:model-value="updateTemplateDebounced"
      />
    </Tooltip>
  </div>

  <div class="field">
    <strong>Name</strong>
    <div style="display: flex; justify-content: space-between; align-items: flex-end">
      <input
        v-model="reportTemplate.name"
        type="text"
        @update:model-value="updateTemplateDebounced"
      />

      <Popper
        class="dropdown-menu-container"
        placement="bottom-end"
        :offset-distance="2"
        :interactive="false"
        @open="isDropdownOpen = true"
        @close="isDropdownOpen = false"
      >
        <button data-testid="report-template-dropdown-menu">
          <FontAwesomeIcon icon="bars" />
        </button>

        <template #content>
          <div class="dropdown-menu">
            <div class="menu-item" @click="onDuplicateReportTemplate">
              <FontAwesomeIcon icon="copy" fixed-width />
              Duplicate template
            </div>
            <div class="divider" />
            <a
              v-if="selectedVersion"
              class="menu-item"
              data-testid="export-template-to-file"
              @click="onDownloadTemplate"
            >
              <FontAwesomeIcon icon="download" fixed-width />
              Export to file
            </a>
            <div class="menu-item" @click="fileInputElement?.click()">
              <FontAwesomeIcon icon="upload" fixed-width />
              Import from file

              <input
                ref="fileInputElement"
                type="file"
                accept=".json"
                hidden
                data-testid="import-template-from-file"
                @change="onImportTemplate"
              />
            </div>
            <div class="divider" />
            <div class="menu-item" @click="onDeleteReportTemplate">
              <FontAwesomeIcon icon="trash" fixed-width />
              Delete template
            </div>
          </div>
        </template>
      </Popper>
    </div>
  </div>

  <div v-if="selectedVersion" class="report-template-container">
    <div class="selected-template-header-container">
      <div
        class="selected-template-header"
        :class="{ published: isSelectedVersionPublished, editing: isEditing }"
      >
        <div class="state-message" :class="{ published: isSelectedVersionPublished }">
          <template v-if="isSelectedVersionPublished">
            <FontAwesomeIcon icon="circle" size="xs" class="icon" />
            Published {{ formatDateTime(selectedVersion.publishedAt, { includeTime: true }) }}
          </template>
          <template v-else-if="isEditing">
            <FontAwesomeIcon icon="pen" />
            Editing draft
          </template>
          <template v-else>
            <FontAwesomeIcon icon="file-pen" />
            Draft
          </template>
        </div>

        <template v-if="isEditing">
          <div
            class="save-status"
            :class="saveStatus"
            data-testid="save-status"
            :data-test-save-count="saveCount"
          >
            <template v-if="saveStatus === 'success'">
              <FontAwesomeIcon icon="check" data-testid="report-template-saved" />
              Saved
            </template>
            <template v-else-if="saveStatus === 'inProgress'">
              <LoadingIndicator data-testid="report-template-saving" />
              Saving
            </template>
            <template v-else-if="saveStatus === 'error'">
              <FontAwesomeIcon icon="warning" />
              Error saving template
            </template>
          </div>

          <div id="report-template-undo-redo-buttons" style="display: contents">
            <Tooltip :content="undoTooltip" @click="onUndo">
              <FontAwesomeIcon
                icon="undo"
                size="lg"
                class="undo-redo-icon"
                :class="{ disabled: !isUndoAvailable }"
              />
            </Tooltip>
            <Tooltip :content="redoTooltip" @click="onRedo">
              <FontAwesomeIcon
                icon="redo-alt"
                size="lg"
                class="undo-redo-icon"
                :class="{ disabled: !isRedoAvailable }"
              />
            </Tooltip>
          </div>

          <Tooltip :visible="!isEditingStyle" content="Report styling options">
            <Popper
              class="report-style-popper"
              placement="bottom-end"
              :offset-distance="4"
              data-testid="report-style-popper"
              @open="isEditingStyle = true"
              @close="isEditingStyle = false"
            >
              <div
                class="report-style-btn"
                :class="{ active: isEditingStyle }"
                data-testid="report-style-btn"
                @click="isEditingStyle = !isEditingStyle"
              >
                <FontAwesomeIcon icon="palette" size="lg" />
              </div>

              <template #content>
                <div class="report-styles">
                  <div class="report-style-heading large">Styling Options</div>
                  <div class="report-style-heading">Font</div>

                  <DropdownWidget
                    :model-value="selectedVersion.structure.font"
                    :items="
                      Object.values(ReportTemplateFont).map((font) => ({
                        value: font,
                        text: reportFontDisplayNames[font],
                      }))
                    "
                    data-testid="font-picker"
                    @update:model-value="
                      (font: string) =>
                        onMutateSelectedVersionStructure(
                          createFontMutation(font as ReportTemplateFont)
                        )
                    "
                  />

                  <div class="report-style-heading">Font Sizes</div>

                  <ReportTemplateEditFontSize
                    name="Title"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Title"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditFontSize
                    name="Header"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Header"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditFontSize
                    name="Footer"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Footer"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditFontSize
                    name="Normal"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Normal"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditFontSize
                    name="Heading"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Heading"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditFontSize
                    name="Measurement"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Measurement"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditFontSize
                    name="Table"
                    :structure="selectedVersion.structure"
                    :option="ReportFontSizeOption.Table"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <div class="report-style-heading">Margins</div>

                  <ReportTemplateEditMargins
                    name="Top"
                    :structure="selectedVersion.structure"
                    :option="ReportMarginOption.Top"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditMargins
                    name="Bottom"
                    :structure="selectedVersion.structure"
                    :option="ReportMarginOption.Bottom"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditMargins
                    name="Horizontal"
                    :structure="selectedVersion.structure"
                    :option="ReportMarginOption.Horizontal"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <div class="report-style-heading">Spacing</div>

                  <ReportTemplateEditSpacing
                    name="Field"
                    :structure="selectedVersion.structure"
                    :spacing="ReportSpacingOption.Field"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditSpacing
                    name="Table"
                    :structure="selectedVersion.structure"
                    :spacing="ReportSpacingOption.Table"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditSpacing
                    name="Heading"
                    :structure="selectedVersion.structure"
                    :spacing="ReportSpacingOption.Heading"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <ReportTemplateEditSpacing
                    name="Section"
                    :structure="selectedVersion.structure"
                    :spacing="ReportSpacingOption.Section"
                    @update="onMutateSelectedVersionStructure"
                  />

                  <div class="report-style-heading">Components</div>

                  <DraggableList @reorder="onReorderComponents">
                    <div
                      v-for="component in selectedVersion.structure.layout.components"
                      :key="component.name"
                      style="display: flex; gap: 4px; align-items: center"
                    >
                      <Checkbox
                        :model-value="component.isVisible"
                        @update:model-value="
                          onMutateSelectedVersionStructure(
                            createLayoutComponentVisibilityToggleMutation(component.name)
                          )
                        "
                      >
                        {{ getReportComponentDisplayText(component.name) }}
                      </Checkbox>

                      <div class="drag-handle" style="margin-left: auto">
                        <FontAwesomeIcon icon="grip-lines-vertical" />
                      </div>
                    </div>
                  </DraggableList>

                  <div class="report-style-heading">Other</div>

                  <Checkbox
                    v-model="selectedVersion.structure.layout.isLeftPaneVisible"
                    @update:model-value="onMutateSelectedVersionStructure(createLayoutMutation())"
                  >
                    Show left pane (deprecated)
                  </Checkbox>
                  <Checkbox
                    v-model="selectedVersion.structure.layout.isIndicationFieldVisible"
                    @update:model-value="onMutateSelectedVersionStructure(createLayoutMutation())"
                  >
                    Show built-in indication field
                  </Checkbox>
                  <Checkbox
                    v-model="selectedVersion.structure.layout.isMedicalHistoryFieldVisible"
                    @update:model-value="onMutateSelectedVersionStructure(createLayoutMutation())"
                  >
                    Show built-in medical history field
                  </Checkbox>
                </div>
              </template>
            </Popper>
          </Tooltip>
        </template>

        <Tooltip
          v-if="!isEditing && !isSelectedVersionPublished && reportTemplate.versions.length > 1"
          content="Delete draft"
          placement="left"
          :offset-distance="0"
        >
          <button style="background: none" @click="onDeleteVersion">
            <FontAwesomeIcon icon="trash" />
          </button>
        </Tooltip>

        <button
          v-if="!isEditing"
          class="accented"
          :disabled="selectedVersion === undefined"
          data-testid="edit-report-template-button"
          @click="onMakeChanges"
        >
          <FontAwesomeIcon icon="pen" />
          Edit
        </button>

        <button
          v-if="isEditing"
          class="accented"
          data-testid="done-report-template-button"
          @click="isEditing = false"
        >
          <FontAwesomeIcon icon="check" />
          Done
        </button>

        <button
          v-else-if="!isSelectedVersionPublished"
          class="accented"
          data-testid="publish-report-template-button"
          @click="onPublishVersion"
        >
          <FontAwesomeIcon icon="check" />
          Publish
        </button>
      </div>
    </div>

    <div class="selected-version-container">
      <ReportTemplateVersionEdit
        class="selected-version"
        :report-template-version="selectedVersion"
        :mode="
          !isSelectedVersionPublished && isEditing
            ? ReportContentMode.EditReportStructure
            : ReportContentMode.ViewReportStructure
        "
        :display-mode="StudyReportType.Final"
        @mutate-structure="onMutateSelectedVersionStructure"
      />
    </div>

    <button
      class="try-button accented"
      data-testid="try-report-template-button"
      @click="
        isTryVersionModalVisible = true;
        isShowingPdf = false;
      "
    >
      <FontAwesomeIcon icon="hand-pointer" />
      Try It Out
    </button>

    <ActivityOverlay v-if="activityText" :text="activityText" />
  </div>

  <Modal
    v-if="isTryVersionModalVisible && selectedVersion"
    centered
    title="Trying Report Template"
    @header-button-click="isTryVersionModalVisible = false"
  >
    <div class="try-report-template-modal" data-testid="try-report-template-modal">
      <div class="selected-version-container" :class="{ 'show-pdf': isShowingPdf }">
        <ReportTemplateVersionEdit
          :report-template-version="selectedVersion"
          :mode="ReportContentMode.EditReportContent"
          :show-pdf="isShowingPdf"
          :display-mode="
            isShowingPreliminaryReport ? StudyReportType.Preliminary : StudyReportType.Final
          "
          class="selected-version"
        />
      </div>

      <div class="footer">
        <ToggleSwitch v-model="isShowingPdf"> View final PDF </ToggleSwitch>

        <ToggleSwitch v-model="isShowingPreliminaryReport">Show preliminary report</ToggleSwitch>

        <div style="margin-left: auto">
          <i>Changes made in this window are not saved</i>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import Modal from "@/components/Modal.vue";
import Popper from "@/components/Popper.vue";
import Tooltip from "@/components/Tooltip.vue";
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke, useDebounceFn } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import saveAs from "file-saver";
import type { SortableEvent } from "sortablejs";
import { computed, ref, watch } from "vue";
import type { ReportTemplateCreateResponseDto } from "../../../backend/src/reporting/dto/report-template-create.dto";
import type { ReportTemplateGetOneResponseDto } from "../../../backend/src/reporting/dto/report-template-get-one.dto";
import type { ReportTemplateVersionCreateResponseDto } from "../../../backend/src/reporting/dto/report-template-version-create.dto";
import {
  ReportComponent,
  ReportFontSizeOption,
  ReportMarginOption,
  ReportSpacingOption,
  ReportStructure,
  ReportTemplateFont,
  validateReportStructure,
} from "../../../backend/src/reporting/report-structure";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import Checkbox from "../components/Checkbox.vue";
import DraggableList from "../components/DraggableList.vue";
import DropdownWidget from "../components/DropdownWidget.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import { ReportContentMode } from "../reporting/report-content";
import {
  ReportStructureMutation,
  createFontMutation,
  createLayoutComponentMoveMutation,
  createLayoutComponentVisibilityToggleMutation,
  createLayoutMutation,
} from "../reporting/report-structure-mutations";
import { useReportStructureUndoRedo } from "../reporting/report-structure-undo-redo";
import { isInputElementFocused } from "../utils/dom-utils";
import { addNotification } from "../utils/notifications";
import { getRequestErrorMessage } from "../utils/request-helpers";
import { ReportTemplateVersion } from "../utils/study-data";
import ReportTemplateEditFontSize from "./ReportTemplateEditFontSize.vue";
import ReportTemplateEditMargins from "./ReportTemplateEditMargins.vue";
import ReportTemplateEditSpacing from "./ReportTemplateEditSpacing.vue";
import ReportTemplateVersionEdit from "./ReportTemplateVersionEdit.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const reportFontDisplayNames: Record<ReportTemplateFont, string> = {
  [ReportTemplateFont.SansSerif]: "Sans serif (default)",
  [ReportTemplateFont.Gilmer]: "Gilmer",
};

const reportTemplate = ref<ReportTemplateGetOneResponseDto>({
  id: "",
  name: "",
  enabled: false,
  createdAt: new Date(),
  versions: [],
});

const activityText = ref("");

const isShowingPdf = ref(false);
const isShowingPreliminaryReport = ref(false);

async function loadReportTemplate(): Promise<void> {
  activityText.value = "Loading";

  let response: AxiosResponse<ReportTemplateGetOneResponseDto> | undefined = undefined;

  try {
    response = await axios.get<ReportTemplateGetOneResponseDto>(
      `/api/reporting/templates/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: "Failed loading report template" });
    return;
  } finally {
    activityText.value = "";
  }

  reportTemplate.value = response.data;

  // Sort versions by createdAt
  reportTemplate.value.versions.sort((a, b) =>
    (a.createdAt as string).localeCompare(b.createdAt as string)
  );

  selectLatestVersion();
}

watch(() => [props.id], loadReportTemplate, { immediate: true });

async function updateTemplate(): Promise<void> {
  try {
    await axios.patch(`/api/reporting/templates/${props.id}`, {
      name: reportTemplate.value.name,
      enabled: reportTemplate.value.enabled,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating report template" });
    return;
  }

  addNotification({ type: "info", message: "Updated report template" });
}

const updateTemplateDebounced = useDebounceFn(() => {
  void updateTemplate();
}, 1000);

const selectedVersion = ref<ReportTemplateVersion | undefined>();

const isEditing = ref(false);
const isEditingStyle = ref(false);

const isSelectedVersionPublished = computed(() => Boolean(selectedVersion.value?.publishedAt));

function selectLatestVersion(): void {
  selectedVersion.value = reportTemplate.value.versions[reportTemplate.value.versions.length - 1];
  resetUndoHistory();
}

async function onMakeChanges(): Promise<void> {
  if (isSelectedVersionPublished.value) {
    await onCreateNewVersion();
  } else {
    isEditing.value = true;
  }
}

async function onCreateNewVersion(startEditing = true): Promise<void> {
  let response: AxiosResponse<ReportTemplateVersion> | undefined = undefined;

  try {
    activityText.value = "Creating draft";

    response = await axios.post<ReportTemplateVersionCreateResponseDto>(
      `/api/reporting/templates/${props.id}/versions`
    );
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed creating new report template draft",
    });
    return;
  } finally {
    activityText.value = "";
  }

  reportTemplate.value.versions.push(response.data);
  selectLatestVersion();

  addNotification({ type: "info", message: "Created report template draft" });

  if (startEditing) {
    isEditing.value = true;
  }
}

async function onPublishVersion(): Promise<void> {
  if (selectedVersion.value === undefined) {
    return;
  }

  if (!confirm("Are you sure you want to publish this report template?")) {
    return;
  }

  activityText.value = "Publishing template";

  try {
    await axios.patch(
      `/api/reporting/templates/${props.id}/versions/${selectedVersion.value.id}/publish`
    );
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed publishing report template",
    });
    return;
  } finally {
    activityText.value = "";
  }

  await loadReportTemplate();

  addNotification({ type: "info", message: "Published report template" });
}

async function onDeleteVersion(): Promise<void> {
  if (selectedVersion.value === undefined) {
    return;
  }

  if (!confirm("Are you sure you want to delete this report template draft?")) {
    return;
  }

  activityText.value = "Deleting draft";

  try {
    await axios.delete(`/api/reporting/templates/${props.id}/versions/${selectedVersion.value.id}`);
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed deleting report template draft",
    });
    return;
  } finally {
    activityText.value = "";
  }

  reportTemplate.value.versions.pop();
  selectLatestVersion();

  addNotification({ type: "info", message: "Deleted report template draft" });
}

const isTryVersionModalVisible = ref(false);

const saveStatus = ref<"error" | "inProgress" | "success">("success");
const saveCount = ref(0);

async function saveSelectedReportTemplateVersion(): Promise<void> {
  if (selectedVersion.value === undefined) {
    return;
  }

  saveStatus.value = "inProgress";

  try {
    await axios.patch(
      `/api/reporting/templates/${selectedVersion.value.reportTemplateId}/versions/${selectedVersion.value.id}`,
      { structure: selectedVersion.value.structure }
    );
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed saving report template draft",
    });

    saveStatus.value = "error";

    return;
  }

  saveStatus.value = "success";
  saveCount.value++;
}

const saveSelectedReportTemplateVersionDebounced = useDebounceFn(() => {
  void saveSelectedReportTemplateVersion();
}, 500);

function onMutateSelectedVersionStructure(mutation: ReportStructureMutation): void {
  addMutation(mutation);
  void saveSelectedReportTemplateVersionDebounced();
}

const {
  isUndoAvailable,
  isRedoAvailable,
  resetUndoHistory,
  addMutation,
  undo,
  redo,
  undoTooltip,
  redoTooltip,
} = useReportStructureUndoRedo(selectedVersion);

function onUndo(): void {
  if (undo()) {
    void saveSelectedReportTemplateVersionDebounced();
  }
}

function onRedo(): void {
  if (redo()) {
    void saveSelectedReportTemplateVersionDebounced();
  }
}

// Hook up standard keyboard shortcuts for undo/redo
onKeyStroke(["z", "Z"], (evt) => {
  if (isInputElementFocused()) {
    return;
  }

  if (!evt.metaKey && !evt.ctrlKey) {
    return;
  }

  if (evt.shiftKey) {
    onRedo();
  } else {
    onUndo();
  }
});

const isDropdownOpen = ref(false);

async function onDeleteReportTemplate(): Promise<void> {
  if (!confirm("Are you sure you want to delete this report template?")) {
    return;
  }

  activityText.value = "Deleting report template";

  try {
    await axios.delete(`/api/reporting/templates/${props.id}`);
  } catch (error) {
    addNotification({
      type: "error",
      message: getRequestErrorMessage(error) ?? "Failed deleting report template",
    });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Deleted report template" });

  await router.push({ name: "settings-reporting" });
}

async function onDuplicateReportTemplate(): Promise<void> {
  if (selectedVersion.value === undefined) {
    return;
  }

  activityText.value = "Duplicating template";

  try {
    // Create new template
    const newTemplate = await axios.post<ReportTemplateCreateResponseDto>(
      `/api/reporting/templates`,
      { name: `${reportTemplate.value.name} (Copy)` }
    );

    // Create an initial template version
    const newTemplateVersion = await axios.post<ReportTemplateVersionCreateResponseDto>(
      `/api/reporting/templates/${newTemplate.data.id}/versions`
    );

    // Set the initial version's structure to the latest structure of this report template
    await axios.patch(
      `/api/reporting/templates/${newTemplate.data.id}/versions/${newTemplateVersion.data.id}`,
      { structure: selectedVersion.value.structure }
    );

    await router.push({
      name: "settings-reporting-templates-edit",
      params: { id: newTemplate.data.id },
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed duplicating template" });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: "Duplicated template" });
}

function onDownloadTemplate(): void {
  if (selectedVersion.value === undefined) {
    return;
  }

  const jsonStructure = JSON.stringify(selectedVersion.value.structure, null, 2);
  const utf8 = new TextEncoder().encode(jsonStructure);

  saveAs(new Blob([utf8]), `HeartLab - ${reportTemplate.value.name}.report-template.json`);
}

const fileInputElement = ref<HTMLInputElement>();

async function onImportTemplate(): Promise<void> {
  if (!fileInputElement.value || !selectedVersion.value) {
    return;
  }

  const file = fileInputElement.value.files?.item(0);
  if (!file) {
    return;
  }

  // Clear out the value on the input field so that repeated selection of the same file
  // triggers a re-upload
  fileInputElement.value.value = "";

  // Read the selected file as text and parse as JSON
  const jsonData = await new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (): void => resolve(reader.result as string);
    reader.onerror = (): void => {
      addNotification({ type: "error", message: "Failed importing selected template" });
      reject(Error("Failed reading file as text"));
    };
    reader.readAsText(file);
  });

  // Validate the uploaded structure file. This validation will also happen on the backend, but it's
  // nicer to do it up-front now
  let uploadedStructure: ReportStructure | undefined = undefined;
  try {
    uploadedStructure = validateReportStructure(JSON.parse(jsonData));
  } catch (error) {
    console.error("The selected template file is invalid, details:");
    console.error(error);

    addNotification({
      type: "error",
      message: "The selected template file is invalid, please re-export it",
    });

    return;
  }

  // Start a new draft if needed
  if (selectedVersion.value.publishedAt !== null) {
    await onCreateNewVersion(false);
  }

  // Copy the imported content onto the current draft
  selectedVersion.value.structure = uploadedStructure;
  await saveSelectedReportTemplateVersion();
}

function onReorderComponents(event: SortableEvent): void {
  if (
    selectedVersion.value === undefined ||
    event.oldIndex === undefined ||
    event.newIndex === undefined
  ) {
    return;
  }

  onMutateSelectedVersionStructure(
    createLayoutComponentMoveMutation(event.oldIndex, event.newIndex)
  );
}

function getReportComponentDisplayText(component: ReportComponent): string {
  return {
    [ReportComponent.Sections]: "Sections",
    [ReportComponent.MeasurementGroups]: "Measurement Groups",
    [ReportComponent.Signature]: "Signature",
  }[component];
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;

  input {
    width: 300px;
  }
}

.selected-template-header-container {
  position: sticky;
  top: calc(var(--settings-content-vertical-padding) * -1);
  z-index: 2;

  --editing-bg-color: var(--button-accented-disabled-color);
}

.selected-template-header {
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: var(--editing-bg-color);
  padding: 8px;
  transition: background-color 300ms ease;
  border-radius: var(--border-radius) var(--border-radius) 0 0;

  &.published {
    background-color: var(--bg-color-3);
  }
}

.state-message {
  font-weight: bold;
  margin-right: auto;
  display: flex;
  align-items: center;
  line-height: 1em;
  gap: 8px;
  padding-left: 4px;
  color: var(--text-color-2);

  &.published {
    .icon {
      color: var(--confirm-color-2);
    }
  }
}

.report-style-btn {
  cursor: pointer;
  height: 32px;
  width: 32px;
  display: grid;
  place-content: center;

  border-radius: var(--border-radius);
  transition:
    color 100ms ease,
    filter 100ms ease;

  &.active {
    background-color: var(--editing-bg-color);
    filter: brightness(140%);
  }

  &:hover {
    filter: brightness(160%);
  }
}

.save-status {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  min-width: 70px;
  line-height: 1em;

  &.error {
    svg {
      color: #f80000;
    }
  }

  &.success {
    svg {
      color: var(--confirm-color-2);
    }
  }
}

.undo-redo-icon {
  cursor: pointer;
  transition: color 100ms ease;

  &:not(.disabled) {
    &:hover {
      color: var(--text-color-2);
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.selected-version-container {
  flex: 1;
  display: flex;
  justify-content: center;
  background: var(--report-container-bg-color);
  padding: 8px;
}

.selected-version {
  flex: 1;
  margin: 8px;
  max-width: 1000px;
  height: max-content;
  box-shadow: rgba(0, 0, 0, 0.8) 0 0 10px;
  overflow: hidden;
}

.try-report-template-modal {
  height: 80vh;
  width: 100vh;

  max-width: min(1000px, 80vw);
  display: flex;
  flex-direction: column;

  .selected-version-container {
    overflow-y: scroll;

    &.show-pdf {
      padding: 0;
      overflow: hidden;

      .selected-version {
        height: 100%;
        overflow: hidden;
        margin: 0;
      }
    }
  }
}

.try-button {
  position: fixed;
  left: 266px;
  bottom: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1;
}

:deep(.dropdown-menu-container) {
  background-color: var(--bg-color-4);
  border-radius: var(--border-radius) 0 var(--border-radius) var(--border-radius);
  padding: 4px 12px;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px 0;

  .menu-item {
    cursor: pointer;
    transition: color 100ms ease;
    display: grid;
    grid-template-columns: auto auto;
    gap: 8px;
    padding: 0 8px;
    align-items: center;
    justify-content: left;

    &:hover {
      color: var(--text-color-2);
    }
  }

  .divider {
    height: 1px;
    background-color: var(--accent-color-1);
  }
}

.report-template-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: -12px;

  // Extend the gray report template background right to the bottom of the page as this looks better
  // and means the 'Try It Out' button placement looks good at all scroll positions,
  margin-bottom: -24px;
}

.footer {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 8px 0 0;
}

:deep(.report-style-popper) {
  background-color: var(--bg-color-4);
  padding: 0;
}

.report-styles {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 8px 8px 12px 20px;
}

.report-style-heading {
  font-weight: bold;
  padding-top: 4px;
  margin-left: -12px;

  &:first-child {
    padding-top: 0;
  }

  &.large {
    font-size: 1.1em;
  }
}

.drag-handle {
  cursor: grab;
  transition: color 100ms ease;
  align-self: stretch;
  display: grid;
  place-content: center;
  padding: 0 4px;

  &:hover {
    color: var(--text-color-2);
  }
}
</style>
