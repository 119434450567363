<template>
  <div class="back-btn" @click="router.push({ name: 'settings-tenants' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to Tenants
  </div>

  <div class="settings-title" data-testid="tenant-view-title" :data-test-is-loading="isLoading">
    Tenant: {{ tenant.name }}

    <Tooltip
      v-if="!isLoading && !isCurrentTenant"
      :content="`This will initiate a login to ${tenant.name} as your current admin user`"
    >
      <div class="login-btn" @click="openTenantLoginOptions">
        Login
        <FontAwesomeIcon icon="right-to-bracket" />
      </div>
    </Tooltip>
  </div>

  <div class="field">
    <b>Name</b>
    <p>The display name for this tenant that is shown in the top-left corner.</p>
    <input
      v-model="tenant.name"
      type="text"
      class="text-input"
      @update:model-value="updateTenantDebounced"
    />
  </div>

  <div class="field">
    <b>AE Title</b>
    <p>
      The AE title for this tenant that identifies its DIMSE traffic:
      <code class="selectable-text" style="font-weight: bold">
        {{ tenant.aeTitle }}
      </code>
    </p>
  </div>

  <div class="field">
    <b>Salesforce Account ID</b>
    <p>The ID of the Salesforce account to create this tenant's support cases in.</p>
    <input
      v-model="tenant.salesforceAccountId"
      class="text-input"
      type="text"
      data-testid="salesforce-id-input"
      @update:model-value="updateTenantDebounced"
    />
  </div>

  <div class="field">
    <b>Stripe Customer ID</b>
    <p>The ID of the Stripe customer to bill this tenant's usage against.</p>
    <input
      v-model="tenant.stripeCustomerId"
      class="text-input"
      type="text"
      data-testid="stripe-customer-id-input"
      @update:model-value="updateTenantDebounced"
    />
  </div>

  <div class="field">
    <div class="toggle">
      <b>Bill for Studies Uploaded in the Browser</b>
      <ToggleSwitch
        v-model="tenant.isWebUploadBilled"
        data-testid="tenant-web-upload-billing-switch"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether this tenant should be billed for studies that were created by uploading DICOM files in
      the web browser. Some customers have negotiated this to be excluded from their bill.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Bill Split Studies twice</b>
      <ToggleSwitch
        v-model="tenant.isSplitStudyBilledTwice"
        data-testid="tenant-split-study-billing-switch"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether or not split studies will be billed twice for this tenant. If this option is turned
      off, this tenant will only be billed once for every two studies happening within a two hours
      window for the same patient.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Show "Not For Clinical Use" Banner</b>
      <ToggleSwitch
        v-model="tenant.isNotForClinicalUseBannerVisible"
        @update:model-value="updateTenantDebounced"
      />
    </div>
    <p>Whether to show a <i>"Not For Clinical Use"</i> banner at the top of the application.</p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>High Priority Support Cases Allowed</b>
      <ToggleSwitch
        v-model="tenant.isHighPrioritySupportCaseCreationAllowed"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether high priority support cases can be created by users of this tenant. High priority
      support cases result in an on-call person being paged out of hours. This is generally only
      allowed on tenants who are on a paid plan and are using the software in a clinical setting.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Include In Stats</b>
      <ToggleSwitch v-model="tenant.includeInStats" @update:model-value="updateTenantDebounced" />
    </div>

    <p>
      Whether this tenant should be included when calculating overall statistics. This is used to
      exclude demo tenants, testing/internal tenants, etc.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Dictation Permitted</b>
      <ToggleSwitch
        v-model="tenant.isDictationPermitted"
        data-testid="tenant-dictation-switch"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether users in this tenant can use dictation features. When this is enabled, individual
      users of the tenant can enable dictation in their personal settings.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Deidentification Permitted</b>
      <ToggleSwitch
        v-model="tenant.isDeidentificationPermitted"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>Whether users in this tenant can use the deidentification feature.</p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>CT Mode Permitted</b>
      <ToggleSwitch
        v-model="tenant.isCTModePermitted"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>
      Whether users in this tenant can use advanced CT features, such as the ability to enter the 3D
      NRRD-based CT viewer with windowing and slicing by plane enabled.
    </p>
  </div>

  <div class="field">
    <div class="toggle">
      <b>Microsoft SSO Enabled</b>
      <ToggleSwitch
        v-model="tenant.isMicrosoftSsoEnabled"
        @update:model-value="updateTenantDebounced"
      />
    </div>

    <p>Whether Microsoft SSO is enabled for this tenant.</p>
  </div>

  <Modal
    v-if="isGlobalAdminInitiatedLoginModalVisible"
    :center-screen="true"
    title="Login to Tenant"
    @header-button-click="
      isGlobalAdminInitiatedLoginModalVisible = false;
      tenantLoginReason = '';
    "
  >
    <div class="modal-container">
      <WarningBlock>
        <p>
          Warning: You are about to log to another tenant's account. Remember, with great power
          comes great responsibility. Please ensure you have the necessary authorization and use
          this feature with caution.
        </p>
      </WarningBlock>

      <div class="modal-content">
        <h3>Select Tenant Roles for this Session:</h3>

        <div class="role-list">
          <div
            v-for="role in selectableTenantRoles?.roles"
            :key="role.id"
            class="role-item"
            @click="toggleRole(role)"
          >
            <span>{{ role.name }}</span>
            <ToggleSwitch :model-value="selectedTenantRoles.includes(role)" />
          </div>
        </div>

        <div class="reason-section">
          <label for="tenant-login-reason" class="reason-label">Reason for Access:</label>
          <input
            id="tenant-login-reason"
            v-model="tenantLoginReason"
            type="text"
            required
            class="reason-input"
            placeholder="Enter your reason for accessing this tenant"
          />
        </div>

        <div class="modal-footer">
          <button
            class="accented"
            :disabled="tenantLoginReason.trim().length < 5 || selectedTenantRoles.length === 0"
            @click="initiateLogin"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </Modal>
  <ActivityOverlay v-if="isLoading" text="Loading" />
</template>

<script setup lang="ts">
import ActivityOverlay from "@/components/ActivityOverlay.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import Tooltip from "@/components/Tooltip.vue";
import router from "@/router";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { computed, onMounted, ref } from "vue";
import { z } from "zod";
import { SessionCreateResult } from "../../../backend/src/auth/session-create-result";
import type { TenantGetOneResponseDto } from "../../../backend/src/tenants/dto/tenant-get-one.dto";
import {
  TenantGetRolesResponseDtoSchema,
  type TenantGetRolesResponseDto,
  type TenantRole,
} from "../../../backend/src/tenants/global-admin/dto/tenant-get-roles.dto";
import { signInWithGlobalAdminInitiated } from "../auth/authentication";
import { currentTenant } from "../auth/current-session";
import Modal from "../components/Modal.vue";
import WarningBlock from "../components/WarningBlock.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const isLoading = ref(true);
const tenant = ref<TenantGetOneResponseDto>({
  id: "",
  name: "",
  aeTitle: "",
  isNotForClinicalUseBannerVisible: false,
  salesforceAccountId: "",
  stripeCustomerId: "",
  isHighPrioritySupportCaseCreationAllowed: false,
  includeInStats: false,
  isDictationPermitted: false,
  isCTModePermitted: false,
  isWebUploadBilled: false,
  isSplitStudyBilledTwice: false,
  isDeidentificationPermitted: false,
  isMicrosoftSsoEnabled: false,
});

const isCurrentTenant = computed(() => {
  return tenant.value.id === currentTenant.id;
});

const isGlobalAdminInitiatedLoginModalVisible = ref(false);
const selectableTenantRoles = ref<TenantGetRolesResponseDto>();
const selectedTenantRoles = ref<TenantRole[]>([]);
const tenantLoginReason = ref("");

onMounted(async () => {
  isLoading.value = true;

  let response: AxiosResponse<TenantGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<TenantGetOneResponseDto>(`/api/global/tenants/${props.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed loading tenant" });
    return;
  } finally {
    isLoading.value = false;
  }

  tenant.value = response.data;
});

async function openTenantLoginOptions() {
  isGlobalAdminInitiatedLoginModalVisible.value = true;

  await loadTenantRoles();
}

async function loadTenantRoles() {
  try {
    const response = await axios.get(`/api/global/tenants/${props.id}/roles`);

    const parsedData = TenantGetRolesResponseDtoSchema.parse(response.data);
    selectableTenantRoles.value = parsedData;
  } catch (error) {
    if (error instanceof z.ZodError) {
      addNotification({ type: "error", message: "Invalid tenant roles data received" });
    } else {
      addNotification({ type: "error", message: "Failed loading tenant roles" });
    }
  }
}

function updateSelectedRoles(role: TenantRole, isSelected: boolean) {
  if (isSelected) {
    if (!selectedTenantRoles.value.some((r) => r.id === role.id)) {
      selectedTenantRoles.value.push(role);
    }
  } else {
    selectedTenantRoles.value = selectedTenantRoles.value.filter((r) => r.id !== role.id);
  }
}

function toggleRole(role: TenantRole) {
  const isCurrentlySelected = selectedTenantRoles.value.some((r) => r.id === role.id);
  updateSelectedRoles(role, !isCurrentlySelected);
}

async function initiateLogin() {
  const { result, message } = await signInWithGlobalAdminInitiated({
    targetTenantId: tenant.value.id,
    roleIds: selectedTenantRoles.value.map((role) => role.id),
    reason: tenantLoginReason.value,
  });

  switch (result) {
    case SessionCreateResult.Success:
      await router.push({ name: "study-list" });
      break;
    case SessionCreateResult.Failure:
      addNotification({ type: "error", message: message ?? "Failed to login" });
      break;
  }
}

const updateTenantDebounced = useDebounceFn(() => {
  void updateTenant();
}, 1000);

async function updateTenant(): Promise<void> {
  try {
    await axios.patch(`/api/global/tenants/${tenant.value.id}`, tenant.value);
  } catch {
    addNotification({ type: "error", message: "Failed updating tenant" });
    return;
  }

  addNotification({ type: "info", message: "Updated tenant" });
}
</script>

<style scoped lang="scss">
.settings-title {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
}

.toggle {
  display: flex;
  gap: 16px;
  align-items: center;
}

.field {
  display: grid;
  gap: 8px;
}

.text-input {
  width: 220px;
}

p {
  max-width: 500px;
  margin: 0 0 4px;
}

@mixin clickable-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 5px;

  padding: 10px;

  &:hover {
    color: var(--text-color-2);
    background-color: var(--bg-color-3);
  }
}

.login-btn {
  @include clickable-item;
  font-size: 0.9rem;
  gap: 6px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    h3 {
      margin: 0;
      font-size: 1.1rem;
    }

    .role-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      animation: fade-in-and-down 0.5s ease;

      .role-item {
        @include clickable-item;
        justify-content: space-between;
      }
    }

    .reason-section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .reason-label {
        font-weight: bold;
      }

      .reason-input {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 4px;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
    }
  }
}
</style>
