<template>
  <Tooltip :content="!disabled ? tooltip : ''">
    <div class="icon-button" :class="[{ highlight, disabled }]" @click="emits('click')">
      <FontAwesomeIcon v-if="!toggleOptions" :icon="icon" :size="size" v-bind="iconProps" />
      <Chevron
        v-else
        :icon="icon"
        :size="size"
        :is-dropdown-open="toggleOptions?.isOpen ?? false"
        :invert-rotation="toggleOptions?.invertRotation ?? false"
        v-bind="iconProps"
      />
    </div>
  </Tooltip>
</template>

<script setup lang="ts">
/**
 * A simple icon button that renders as a fontawesome icon and emits a click event.
 */
import Tooltip from "@/components/Tooltip.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { toRefs } from "vue";
import Chevron from "./ui/chevron/Chevron.vue";

interface Props {
  /**
   * The fontawesome icon to display. Check icons.ts for the list of exported icons.
   */
  icon: string;
  /**
   * The tooltip to display.
   */
  tooltip?: string;
  /**
   * If true, the button will be disabled.
   */
  disabled?: boolean;
  /**
   * The size of the icon.
   */
  size?:
    | "2xs"
    | "xs"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | "1x"
    | "2x"
    | "3x"
    | "4x"
    | "5x"
    | "6x"
    | "7x"
    | "8x"
    | "9x"
    | "10x";

  /**
   * If true, the button container div will be highlighted
   */
  highlight?: boolean;

  toggleOptions?: {
    /**
     * If true, the button icon spins 180 degrees when open. Useful if this icon button is used to open or close a dropdown.
     */
    isOpen: boolean;
    /**
     * If true, the chevron will rotate 180 degrees clockwise instead of 180 degrees anti-clockwise.
     */
    invertRotation?: boolean;
  };
}

interface Emits {
  (event: "click"): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { icon, tooltip, toggleOptions, disabled, size, ...iconProps } = toRefs(props);
</script>

<style scoped lang="scss">
.icon-button {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius);

  transition: color 100ms ease;

  &:hover:not(.disabled) {
    color: var(--text-color-2);
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  &.highlight {
    height: 26px;
    width: 26px;
    display: grid;
    place-content: center;

    background-color: var(--bg-color-2);

    transition:
      color 100ms ease,
      background-color 100ms ease;

    &:hover {
      background-color: var(--bg-color-4);
      color: var(--text-color-2);
    }
  }
}
</style>
