<template>
  <div class="content">
    <template v-if="hasStudyListViewPermission">
      <div class="study-list-options">
        <FilterInput
          v-model="studyListFilter"
          data-testid="study-list-filter"
          placeholder="Patient name, ID, institution, etc."
          class="study-list-filter"
        />

        <DatePicker
          v-model="studyDateFilter"
          mode="date"
          is-range
          :max-date="new Date()"
          placeholder="Study date"
          data-testid="study-date-advanced-filter"
          background-color="var(--bg-color-1)"
        />

        <StudyTypeDropdown
          v-if="isStudyListColumnVisible(StudyListColumn.StudyType)"
          class="study-list-filter"
          :model-value="studyTypeFilter"
          placeholder="Study type"
          data-testid="study-type-filter"
          @update:model-value="
            (newValue) => (studyTypeFilter = newValue !== StudyType.NotSpecified ? newValue : '')
          "
        />

        <UserDropdown
          v-model="assignedUserFilter"
          placeholder="Assigned to"
          class="study-list-filter"
          data-testid="assigned-user-filter"
          style="max-width: 200px"
        />

        <span class="clear-filters" @click="clearFilters">
          <template
            v-if="
              assignedUserFilter !== '' ||
              studyListFilter !== '' ||
              studyDateFilter !== null ||
              studyTypeFilter !== ''
            "
          >
            Clear filters
          </template>
        </span>

        <b v-if="studiesCount !== undefined" style="text-align: right">
          {{ studiesCount }} {{ studiesCount === 1 ? "result" : "results" }}
        </b>
        <div v-else />

        <IconButton
          icon="file-export"
          tooltip="Export study list"
          @click="showStudyListExportModal = true"
        />

        <BackgroundSelectPopper v-model="selectedBackground" />
      </div>

      <div
        class="studies-table"
        :class="{ 'show-borders': selectedBackground === 'solid' }"
        data-testid="studies-table"
      >
        <div style="display: contents">
          <div class="header-cell" style="padding-left: 0">
            <FilterDropdown
              :filters="studyStatusFilter"
              @update:filters="(newValue) => (studyStatusFilter = newValue)"
            />
          </div>

          <div
            class="header-cell sortable"
            data-testid="patient-name-sort"
            @click="changeSortColumn('patientName')"
          >
            <div class="title">Patient Name</div>
            <FontAwesomeIcon
              v-if="sortColumnName === 'patientName'"
              :icon="sortIconName"
              class="sort-icon"
            />
          </div>

          <div v-if="isStudyListColumnVisible(StudyListColumn.PatientID)" class="header-cell">
            <div class="title">{{ currentTenant.patientIdLabel }}</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.PatientBirthdate)"
            class="header-cell"
            data-testid="patient-birthdate-header"
          >
            <div class="title">Birthdate</div>
          </div>

          <div
            class="header-cell sortable"
            data-testid="study-date-sort"
            @click="changeSortColumn('takenAt')"
          >
            <div class="title no-ellipsis">Study Date</div>
            <FontAwesomeIcon
              v-if="sortColumnName === 'takenAt'"
              :icon="sortIconName"
              class="sort-icon"
            />
          </div>

          <div class="header-cell">
            <div class="title">Modality</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.StudyType)"
            class="header-cell"
            data-testid="study-type-header"
          >
            <div class="title">Type</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Institute)"
            class="header-cell sortable"
            @click="changeSortColumn('institution')"
          >
            <div class="title">Institute</div>
            <FontAwesomeIcon
              v-if="sortColumnName === 'institution'"
              :icon="sortIconName"
              class="sort-icon"
            />
          </div>

          <div v-if="isStudyListColumnVisible(StudyListColumn.PerformedBy)" class="header-cell">
            <div class="title">Performed By</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Trainee)"
            class="header-cell"
            data-testid="trainee-column-header"
          >
            <div class="title">
              {{ currentTenant.traineeLabel }}
            </div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Technician)"
            class="header-cell"
            data-testid="technician-column-header"
          >
            <div class="title">{{ currentTenant.technicianLabel }}</div>
          </div>

          <div
            v-if="isStudyListColumnVisible(StudyListColumn.Physician)"
            class="header-cell"
            data-testid="physician-column-header"
          >
            <div class="title">{{ currentTenant.physicianLabel }}</div>
          </div>

          <div class="header-cell">
            <div class="title">Assignee</div>
          </div>

          <div class="header-cell" />
        </div>
        <StudyListRow
          v-for="(study, studyIndex) in studies"
          :key="study.id"
          :study="study"
          @view-report="viewReport(study)"
          @study-clicked="loadStudy(study)"
          @study-deleted="onStudyDeleted(studyIndex)"
          @patient-identity-updated="reloadStudies"
        />

        <VueEternalLoading
          v-model:is-initial="isReloadRequired"
          class="eternal-loading-status"
          :load="loadStudies"
        >
          <template #loading>
            <Transition name="fade">
              <LoadingIndicator size="2x" />
            </Transition>
          </template>

          <template #no-more> &nbsp; </template>
          <template #no-results> &nbsp; </template>

          <template #error>
            <div class="loading-error">Error loading studies</div>
          </template>
        </VueEternalLoading>
      </div>
    </template>

    <div v-else class="missing-permission-message">
      You do not have permission to view the study list.
      <br />
      <br />
      Please contact an administrator to get access.
    </div>
  </div>

  <ViewReportsModal
    v-if="selectedStudy !== undefined"
    :study-id="selectedStudy.id"
    :reports="selectedStudy.reports"
    @close="selectedStudy = undefined"
  />

  <ExportStudyListModal
    v-if="showStudyListExportModal && hasStudyListExportPermission"
    :study-list-filters="{
      studyDateFilter,
      assignedUserFilter,
    }"
    @close="showStudyListExportModal = false"
  />
</template>

<script lang="ts">
/**
 * This is in a separate script tag to persist the filtering/sorting state
 * when navigating between pages.
 */
import IconButton from "@/components/IconButton.vue";
import router from "@/router";
import { getEmptyStudy, isStudyListColumnVisible, Study } from "@/utils/study-data";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import type { LoadAction } from "@ts-pro/vue-eternal-loading";
import { VueEternalLoading } from "@ts-pro/vue-eternal-loading";
import { useDebounceFn, useLocalStorage, useStorage } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { computed, ref, watch } from "vue";
import { StudyGetManyResponseDto } from "../../../backend/src/studies/dto/study-get-many.dto";
import { StudyType } from "../../../backend/src/studies/study-type";
import { StudyListColumn } from "../../../backend/src/tenants/study-list-column";
import { hasStudyListExportPermission, hasStudyListViewPermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import BackgroundSelectPopper from "../components/BackgroundSelectPopper.vue";
import DatePicker from "../components/DatePicker.vue";
import FilterInput from "../components/FilterInput.vue";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import StudyTypeDropdown from "../components/StudyTypeDropdown.vue";
import UserDropdown from "../components/UserDropdown.vue";
import { BASE_URL } from "../environment";
import { StudyListStatusFilter, StudyReportStatus } from "../reporting/report-status";
import ViewReportsModal from "../reporting/ViewReportsModal.vue";
import ExportStudyListModal from "./ExportStudyListModal.vue";
import FilterDropdown from "./FilterDropdown.vue";
import StudyListRow from "./StudyListRow.vue";

const studyListFilter = ref("");
const studyDateFilter = ref<{ start: Date; end: Date } | null>(null);
const assignedUserFilter = ref("");
const studyTypeFilter = ref("");
</script>

<script setup lang="ts">
const studies = ref<Study[]>([]);
const studiesCount = ref<number>();

const showStudyListExportModal = ref(false);

type SortColumnName = "institution" | "patientName" | "takenAt";

const sortColumnName = useStorage<SortColumnName>("study-list-sort-column-name", "takenAt");
const sortDirection = useStorage<"ASC" | "DESC">("study-list-sort-direction", "DESC");

let offset = 0;

const studyStatusFilter = ref<StudyListStatusFilter[]>([
  { reportStatus: null, filterText: "Not started", value: false },
  ...Object.values(StudyReportStatus).map((reportStatus: StudyReportStatus) => ({
    reportStatus,
    filterText: getReportStatusName(reportStatus),
    value: false,
  })),
]);

function getReportStatusName(reportStatus: StudyReportStatus): string {
  switch (reportStatus) {
    case StudyReportStatus.AmendmentInProgress:
      return "Amendment in progress";
    case StudyReportStatus.PreliminaryReportApproved:
      return "Preliminary approved";
    case StudyReportStatus.ReportFinalized:
      return "Finalized";
    case StudyReportStatus.ReportInProgress:
      return "In progress";
    default:
      return "";
  }
}

async function loadStudies({ loaded, error }: LoadAction): Promise<void> {
  const PAGE_SIZE = 50;

  const query = new URLSearchParams({
    limit: PAGE_SIZE.toString(),
    offset: offset.toString(),
    sortColumnName: sortColumnName.value,
    sortDirection: sortDirection.value,
  });

  if (studyListFilter.value) {
    query.append("filter", studyListFilter.value);
  }

  if (studyDateFilter.value !== null) {
    query.append(
      "takenAtBefore",
      `${DateTime.fromJSDate(studyDateFilter.value.end).toFormat("yyyy-MM-dd")}T23:59:59`
    );
    query.append(
      "takenAtAfter",
      `${DateTime.fromJSDate(studyDateFilter.value.start).toFormat("yyyy-MM-dd")}T00:00:00`
    );
  }

  if (assignedUserFilter.value !== "") {
    query.append("assignedUserFilter", assignedUserFilter.value);
  }

  if (studyTypeFilter.value !== "") {
    query.append("studyTypeFilter", studyTypeFilter.value);
  }

  if (studyStatusFilter.value.some((filter) => filter.value)) {
    query.append(
      "reportStatus",
      studyStatusFilter.value
        .filter((filter) => filter.value)
        .map((filter) => filter.reportStatus ?? "notStarted")
        .join(",")
    );
  }

  let response: AxiosResponse<StudyGetManyResponseDto> | undefined = undefined;

  try {
    response = await axios.get<StudyGetManyResponseDto>(`/api/studies?${query.toString()}`);
  } catch (exception) {
    error();
    return;
  }

  studies.value.push(...response.data.studies.map((study) => ({ ...getEmptyStudy(), ...study })));

  studiesCount.value = response.data.count;

  offset += PAGE_SIZE;

  loaded(response.data.studies.length, PAGE_SIZE);
}

const isReloadRequired = ref(true);

function reloadStudies(): void {
  studies.value = [];
  offset = 0;
  isReloadRequired.value = true;
}

watch([studyListFilter], useDebounceFn(reloadStudies, 500));
watch(
  [
    assignedUserFilter,
    studyTypeFilter,
    sortColumnName,
    sortDirection,
    studyDateFilter,
    studyStatusFilter,
  ],
  reloadStudies
);

async function loadStudy(study: Study): Promise<void> {
  await router.push({ name: "study-view", params: { id: study.id } });
}

function onStudyDeleted(studyIndex: number): void {
  studies.value.splice(studyIndex, 1);
  offset--;
}

const selectedStudy = ref<Study>();

function viewReport(study: Study): void {
  selectedStudy.value = study;
}

function changeSortColumn(columnName: SortColumnName): void {
  if (sortColumnName.value === columnName) {
    sortDirection.value = sortDirection.value === "DESC" ? "ASC" : "DESC";
    return;
  }

  sortColumnName.value = columnName;
  sortDirection.value = "ASC";
}

const sortIconName = computed(() =>
  sortDirection.value === "ASC" ? "chevron-up" : "chevron-down"
);

const cssGridTemplateColumns = computed(() => {
  // Status, Patient name
  const columns = ["32px", "minmax(200px, 2fr)"];

  if (isStudyListColumnVisible(StudyListColumn.PatientID)) {
    columns.push("minmax(100px, 1.5fr)");
  }

  if (isStudyListColumnVisible(StudyListColumn.PatientBirthdate)) {
    columns.push("max-content");
  }

  // Study date, Modality
  columns.push("max-content", "80px");

  // The following columns are optional
  for (const column of [
    StudyListColumn.Institute,
    StudyListColumn.PerformedBy,
    StudyListColumn.Trainee,
    StudyListColumn.Technician,
    StudyListColumn.Physician,
    StudyListColumn.StudyType,
  ]) {
    if (isStudyListColumnVisible(column)) {
      columns.push("minmax(150px, 1.5fr)");
    }
  }

  // Fixed columns: Assignee, Icons, View Report
  columns.push("minmax(150px, 1.5fr)", "minmax(78px, auto)");

  return columns.join(" ");
});

const studyListOptionsGridTemplateColumns = computed(() => {
  const columns = ["320px 240px auto auto"];

  // Study type
  if (isStudyListColumnVisible(StudyListColumn.StudyType)) {
    columns.push("auto");
  }

  columns.push("1fr auto auto");

  return columns.join(" ");
});

function clearFilters(): void {
  assignedUserFilter.value = "";
  studyDateFilter.value = null;
  studyListFilter.value = "";
  studyTypeFilter.value = "";
}

const selectedBackground = useLocalStorage("study-list-background", "solid");

const backgroundImageStyle = computed(() => {
  if (selectedBackground.value === "solid") {
    return "none";
  }

  return `url("${BASE_URL}backgrounds/${selectedBackground.value}.png")`;
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.content,
.header-cell {
  background-attachment: fixed;
  background-size: cover;
  background-color: var(--bg-color-2);
  background-image: v-bind("backgroundImageStyle");
}

.study-list-options {
  display: grid;
  grid-template-columns: v-bind("studyListOptionsGridTemplateColumns");
  align-items: center;
  gap: 12px;
  padding: 12px 16px 12px 12px;
}

.study-list-filter {
  background: var(--bg-color-1);
}

.clear-filters {
  text-decoration: underline;
  margin-top: 4px;
  cursor: pointer;
  color: var(--accent-color-1);
  transition: color 100ms ease;

  &:hover {
    color: var(--accent-color-2);
  }
}

.studies-table {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  margin: 0 1rem;

  display: grid;
  grid-template-columns: v-bind("cssGridTemplateColumns");
  grid-auto-rows: 32px;

  &.show-borders {
    :deep(> .grid-table-row) {
      > * {
        border-bottom: 1px solid #434343;

        &:first-child {
          border-left: 1px solid #434343;
        }

        &:nth-child(-n + 2) {
          background-color: var(--bg-color-2);
          position: sticky;
          left: 0;
          z-index: 1;
        }

        &:nth-child(2) {
          left: 32px;
          border-right: 1px solid #434343;
          margin-right: -1px;
          border-left: none;
        }

        &:last-child {
          background-color: var(--bg-color-2);
          border: 1px solid #434343;
          border-top: none;
          position: sticky;
          right: 0;
        }
      }

      &:hover,
      &.selected {
        > * {
          background-color: var(--bg-color-4);
        }
      }
    }

    .header-cell {
      &:first-child {
        border-radius: 5px 0 0 0;
        box-shadow: 10px -10px 0 10px var(--bg-color-2);
        border-left: 1px solid #434343;
      }

      &:nth-child(-n + 2) {
        position: sticky;
        left: 0;
        z-index: 2;
      }

      &:nth-child(2) {
        left: 32px;
        border-right: 1px solid #434343;
        border-left: none;
        margin-right: -1px;
      }

      &:last-child {
        border-radius: 0 5px 0 0;
        position: sticky;
        border: 1px solid #434343;
        right: 0;
        top: 0;
        z-index: 99;
        box-shadow: 10px -10px 0 10px var(--bg-color-2);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-color-1);
      }
    }
  }
}

.header-cell {
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  transition: color 1000ms ease;
  z-index: 1;
  padding-left: 8px;

  border-top: 1px solid var(--border-color-1);
  background-color: #2d2d2d;

  &.sortable {
    cursor: pointer;
    gap: 12px;

    &:hover {
      color: var(--text-color-2);

      .sort-icon {
        color: var(--accent-color-2);
      }
    }

    .sort-icon {
      color: var(--accent-color-1);
      transition: color 100ms ease;
      padding-right: 12px;
    }
  }

  .title {
    grid-area: title;
    font-weight: bold;
    font-size: 1.1em;
    white-space: nowrap;

    &:not(.no-ellipsis) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.eternal-loading-status {
  padding: 32px 0;
  display: flex;
  align-items: center;
  position: sticky;
  justify-content: center;
  left: 50%;
}

.loading-error {
  font-weight: bold;
  color: red;
  padding-top: 20px;
}

.missing-permission-message {
  flex: 1;
  display: grid;
  place-content: center;
  text-align: center;
}
</style>
