import type { Volume } from "three/examples/jsm/Addons.js";
import { shallowRef } from "vue";
import type { StudySeries } from "../../utils/study-data";
import type { CTModel, CTSliceDirection } from "./ct-model";

/**
 * NOTE: The actual feature implemented with crosshairs is known medically as multi-planar
 * reconstruction (MPR). However, we refer to it as crosshairs in the codebase for brevity.
 **/

/**
 * Represents the active models for each crosshair direction.
 */
export interface CrosshairModels {
  [CTSliceDirection.Axial]: CTModel;
  [CTSliceDirection.Coronal]: CTModel;
  [CTSliceDirection.Sagittal]: CTModel;
}

/**
 * The current state of the crosshairs. The crosshairs will enter the loading state when the MPR
 * button is clicked, and will enter the active state when the clip grid has been set up to support
 * MPR and the crosshairs are ready to be displayed.
 */
export enum CrosshairsStoreState {
  Inactive = "inactive",
  Loading = "loading",
  Active = "active",
}

interface InactiveCrosshairs {
  state: CrosshairsStoreState.Inactive;
}

interface LoadingCrosshairs {
  state: CrosshairsStoreState.Loading;
  seriesId: string;
}

interface ActiveCrosshairs {
  state: CrosshairsStoreState.Active;
  series: StudySeries;
  models: CrosshairModels;
  volume: Volume;
}

type CrosshairsStore = InactiveCrosshairs | LoadingCrosshairs | ActiveCrosshairs;

/**
 * The global ref that holds the current state of the crosshairs (multi-planar reconstruction).
 *
 * This is specifically NOT a Pinia store because we need to manage reactivity ourselves. A major
 * performance issue was caused in testing with Pinia as for every update of slice position or
 * windowing, the entire store is refreshed which means that every tick would rapidly increase the
 * time to set the slice or windowing refs, causing extreme lag to build quickly.
 *
 * Using shallow refs with Pinia isn't an option here either because although Pinia supports basic
 * shallow reactivity, a shallow reactive object containing a reactive ref for partial object
 * reactivity doesn't work as Pinia performs a deep UnwrapRef<T> on setup store state.
 *
 * See https://github.com/vuejs/pinia/discussions/810.
 */
export const crosshairsStore = shallowRef<CrosshairsStore>({
  state: CrosshairsStoreState.Inactive,
});

export function exitCrosshairs(): void {
  crosshairsStore.value = {
    state: CrosshairsStoreState.Inactive,
  };
}

export function beginCrosshairsLoading(seriesId: string): void {
  crosshairsStore.value = {
    state: CrosshairsStoreState.Loading,
    seriesId,
  };
}

export function setActiveCrosshairs(series: StudySeries, models: CrosshairModels, volume: Volume) {
  crosshairsStore.value = {
    state: CrosshairsStoreState.Active,
    series,
    models,
    volume,
  };
}
