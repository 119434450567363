import { clamp } from "lodash";
import { computed, ref, watch } from "vue";
import { crosshairsStore, CrosshairsStoreState } from "./ct-crosshairs";
import type { CTModel } from "./ct-model";
import { CTSliceDirection } from "./ct-model";

/**
 * A CTCrosshairsRenderer is responsible for rendering the crosshairs on the 2D canvas that overlays
 * the 3D CT viewer. The renderer is responsible for drawing the crosshairs and handling mouse
 * events to allow the user to move the crosshairs. Note that crosshair rotation (i.e. the ability
 * to view oblique slices), adjusting for camera pan or zoom are not supported at present.
 *
 * As our CT features are built out, we should move to a design that more tightly couples the 2D
 * crosshairs with the 3D CT viewer, likely via an adapter that links the two together.
 */
export interface CTCrosshairsRenderer {
  render: () => void;
  onCanvasMouseDown: (event: MouseEvent) => boolean;
  onCanvasMouseMove: (event: MouseEvent) => boolean;
  onCanvasMouseUp: () => void;
}

interface CrosshairPosition {
  verticalLineX: number;
  horizontalLineY: number;
}

export function createCTCrosshairsRenderer(args: {
  model: CTModel;
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
}): CTCrosshairsRenderer {
  const { model, canvas, ctx } = args;

  const isChangingPosition = ref(false);
  const isChangingVertical = ref(false);
  const isChangingHorizontal = ref(false);

  let intersectionEditOffset = [0, 0];

  const volumeRASDimensions = computed(() =>
    crosshairsStore.value.state === CrosshairsStoreState.Active
      ? crosshairsStore.value.volume.RASDimensions
      : undefined
  );

  const crosshairSeries = computed(() =>
    crosshairsStore.value.state === CrosshairsStoreState.Active
      ? crosshairsStore.value.series
      : undefined
  );

  const crosshairModels = computed(() =>
    crosshairsStore.value.state === CrosshairsStoreState.Active
      ? crosshairsStore.value.models
      : undefined
  );

  function drawLineWithGap(
    position: number,
    gapPosition: number,
    color: string,
    isHorizontal: boolean
  ) {
    const gapSize = 20;

    ctx.lineWidth = (isHorizontal ? isChangingHorizontal : isChangingVertical).value ? 2 : 1;
    ctx.strokeStyle = color;

    ctx.beginPath();
    ctx.moveTo(isHorizontal ? 0 : position, isHorizontal ? position : 0);
    ctx.lineTo(
      isHorizontal ? gapPosition - gapSize : position,
      isHorizontal ? position : gapPosition - gapSize
    );
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(
      isHorizontal ? gapPosition + gapSize : position,
      isHorizontal ? position : gapPosition + gapSize
    );
    ctx.lineTo(isHorizontal ? canvas.width : position, isHorizontal ? position : canvas.height);
    ctx.stroke();
  }

  function getCrosshairPositions() {
    if (crosshairModels.value === undefined || volumeRASDimensions.value === undefined) {
      return { verticalLineX: 0, horizontalLineY: 0 };
    }

    const { horizontalDirection, verticalDirection } = getCrosshairDirectionsForSliceDirection(
      model.sliceDirection.value
    );

    const xDimension = volumeRASDimensions.value[getSliceDirectionIndex(horizontalDirection)];
    const yDimension = volumeRASDimensions.value[getSliceDirectionIndex(verticalDirection)];

    const horizontalSlicePosition =
      crosshairModels.value[horizontalDirection].sliceNumber.value /
      crosshairModels.value[horizontalDirection].maxSliceNumber.value;

    const verticalSlicePosition =
      crosshairModels.value[verticalDirection].sliceNumber.value /
      crosshairModels.value[verticalDirection].maxSliceNumber.value;

    // The following assumes that the camera frustrum width is equal to RASDims[0] and the height is
    // equal to RASDims[1]. Currently it's only possible for the camera to be set up in this way but
    // this needs to be reworked to use the actual camera frustrum dimensions when we allow the
    // camera to zoom or pan. We'd likely need an adapter object between the CTRenderer and this
    // renderer to link the current camera frustrum dims to the 2D canvas crosshairs are painted to.

    const horizontalCutRange = (xDimension / yDimension) * canvas.height;

    function adjustForCutRange(
      slicePosition: number,
      cutRange: number,
      maxCutRange: number
    ): number {
      return slicePosition * cutRange + (maxCutRange - cutRange) / 2;
    }

    return {
      verticalLineX: verticalSlicePosition * canvas.width,
      horizontalLineY: adjustForCutRange(
        horizontalSlicePosition,
        horizontalCutRange,
        canvas.height
      ),
    };
  }

  function render(): void {
    if (
      crosshairSeries.value === undefined ||
      crosshairModels.value === undefined ||
      volumeRASDimensions.value === undefined
    ) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.lineWidth = 2;

    const { horizontalDirection, verticalDirection } = getCrosshairDirectionsForSliceDirection(
      model.sliceDirection.value
    );

    const { verticalLineX, horizontalLineY } = getCrosshairPositions();

    drawLineWithGap(
      horizontalLineY,
      verticalLineX,
      getColorForSliceDirection(horizontalDirection),
      true
    );
    drawLineWithGap(
      verticalLineX,
      horizontalLineY,
      getColorForSliceDirection(verticalDirection),
      false
    );
  }

  function getMousePoint(event: MouseEvent): number[] {
    const clientRect = canvas.getBoundingClientRect();
    return [event.clientX - clientRect.x, event.clientY - clientRect.y];
  }

  function isNearVerticalLine(pt: number[], crosshairPos: CrosshairPosition): boolean {
    return pt[0] >= crosshairPos.verticalLineX - 40 && pt[0] < crosshairPos.verticalLineX;
  }

  function isNearHorizontalLine(pt: number[], crosshairPos: CrosshairPosition): boolean {
    return pt[1] >= crosshairPos.horizontalLineY - 40 && pt[1] < crosshairPos.horizontalLineY;
  }

  function onCanvasMouseDown(event: MouseEvent): boolean {
    const pt = getMousePoint(event);
    const crosshairPositions = getCrosshairPositions();

    isChangingHorizontal.value = isNearHorizontalLine(pt, crosshairPositions);
    isChangingVertical.value = isNearVerticalLine(pt, crosshairPositions);

    if (isChangingHorizontal.value || isChangingVertical.value) {
      intersectionEditOffset = [
        pt[0] - crosshairPositions.verticalLineX,
        pt[1] - crosshairPositions.horizontalLineY,
      ];
      return true;
    }

    return false;
  }

  function onCanvasMouseMove(event: MouseEvent): boolean {
    const pt = getMousePoint(event);
    const crosshairPositions = getCrosshairPositions();

    canvas.style.cursor =
      isNearHorizontalLine(pt, crosshairPositions) || isNearVerticalLine(pt, crosshairPositions)
        ? "grab"
        : "default";

    if (
      volumeRASDimensions.value &&
      crosshairModels.value &&
      (isChangingVertical.value || isChangingHorizontal.value)
    ) {
      const offsetMouse = [pt[0] - intersectionEditOffset[0], pt[1] - intersectionEditOffset[1]];

      const { horizontalDirection, verticalDirection } = getCrosshairDirectionsForSliceDirection(
        model.sliceDirection.value
      );

      if (isChangingVertical.value) {
        const verticalModel = crosshairModels.value[verticalDirection];
        verticalModel.sliceNumber.value =
          (offsetMouse[0] / canvas.width) * verticalModel.maxSliceNumber.value;
      }

      if (isChangingHorizontal.value) {
        const horizontalModel = crosshairModels.value[horizontalDirection];
        const xDimension = volumeRASDimensions.value[getSliceDirectionIndex(horizontalDirection)];
        const yDimension = volumeRASDimensions.value[getSliceDirectionIndex(verticalDirection)];

        const scaleFactor = (xDimension / yDimension) * canvas.height;
        const adjustmentToCenter = (canvas.height - scaleFactor) / 2;

        horizontalModel.sliceNumber.value =
          clamp((offsetMouse[1] - adjustmentToCenter) / scaleFactor, 0, 1) *
          horizontalModel.maxSliceNumber.value;
      }
    }

    return false;
  }

  function onCanvasMouseUp() {
    isChangingPosition.value = false;
    isChangingVertical.value = false;
    isChangingHorizontal.value = false;
  }

  const unwatchExitCrosshairs = watch(
    crosshairsStore,
    () => {
      if (crosshairsStore.value.state === CrosshairsStoreState.Inactive) {
        destroyRenderer();
      }
    },
    { once: true }
  );

  function destroyRenderer() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    unwatchExitCrosshairs();
  }

  return { render, onCanvasMouseDown, onCanvasMouseMove, onCanvasMouseUp };
}

/**
 * Returns the index of the slice direction in terms of xyz dimensions. The index of the dimension
 * returned (e.g. x = 0 = sagittal, z = 2 = axial) is normal to the slice plane direction provided.
 */
function getSliceDirectionIndex(sliceDirection: CTSliceDirection): number {
  return [CTSliceDirection.Sagittal, CTSliceDirection.Coronal, CTSliceDirection.Axial].indexOf(
    sliceDirection
  );
}

/**
 * Returns the horizontal and vertical slice directions (i.e. the direction represented by the
 * horizontal and vertical crosshair lines respectively) for the given slice direction.
 */
function getCrosshairDirectionsForSliceDirection(sliceDirection: CTSliceDirection): {
  horizontalDirection: CTSliceDirection;
  verticalDirection: CTSliceDirection;
} {
  return {
    [CTSliceDirection.Axial]: {
      horizontalDirection: CTSliceDirection.Coronal,
      verticalDirection: CTSliceDirection.Sagittal,
    },
    [CTSliceDirection.Coronal]: {
      horizontalDirection: CTSliceDirection.Axial,
      verticalDirection: CTSliceDirection.Sagittal,
    },
    [CTSliceDirection.Sagittal]: {
      horizontalDirection: CTSliceDirection.Axial,
      verticalDirection: CTSliceDirection.Coronal,
    },
  }[sliceDirection];
}

export function getColorForSliceDirection(sliceDirection: CTSliceDirection): string {
  return {
    [CTSliceDirection.Axial]: "red",
    [CTSliceDirection.Coronal]: "yellow",
    [CTSliceDirection.Sagittal]: "green",
  }[sliceDirection];
}
