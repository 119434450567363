import axios from "axios";
import type { WebGLRenderer } from "three";
import type { ComputedRef, Ref } from "vue";
import { computed, ref, watch } from "vue";
import { NRRDProcessState } from "../../../../backend/src/studies/study-clip-processed-files";
import type { Study, StudyClip, StudySeries } from "../../utils/study-data";
import type { CanvasContainerRect } from "../clip-viewer/clip-renderer-2d";
import { useStudyViewImageData } from "../study-view-image-data";
import { isCT3DSeries } from "./ct-helpers";
import type { CTRenderer } from "./ct-renderer";
import { createCTRenderer } from "./ct-renderer";
import { ctSettings } from "./ct-settings";

export enum CTSliceDirection {
  Axial = "Axial",
  Coronal = "Coronal",
  Sagittal = "Sagittal",
}

/**
 * A CTModel is responsible for managing the state of a CT viewer. On creation it requests a volume
 * to be loaded via the study view image data store as well as a CTRenderer which will be assigned
 * to the WebGL canvas to display the slices on. A CTModel holds all information about the current
 * state of the slice being displayed such as windowing & slice number/direction and requests a
 * redraw from the CTRenderer when these change.
 */
export interface CTModel {
  /** The series of the CT that is being displayed */
  readonly series: StudySeries;

  /** The faked clip representing the series that is being displayed. */
  readonly clip: StudyClip;

  /** The slice plane that is being displayed from this CT. */
  sliceDirection: Ref<CTSliceDirection>;

  /** The current slice number being displayed in the LPS coordinate system. */
  sliceNumber: Ref<number>;

  /**
   * The maximum slice number that can be obtained from this CT & slice direction, i.e. the value
   * of the dimension that is being sliced (e.g. xy slice: size in the z-direction).
   */
  maxSliceNumber: ComputedRef<number>;

  /** Whether the CT series is loaded and ready to view. */
  isLoading: Ref<boolean>;

  /** The text to display while the CT NRRD loads in */
  loadingText: ComputedRef<string>;

  /** Steps the slice number by the given number of frames/slices to step. */
  onStepFrame(delta: number): void;

  /**
   * Provides a Three WebGLRenderer to this model. This is so the renderer can be given after the
   * model is created, which instantiates the CTViewer and creates the canvas that the renderer
   * is linked to.
   */
  provideRenderer(webglRenderer: WebGLRenderer, canvasRect: CanvasContainerRect): void;

  /** Destroys the model, cleaning up any watchers or other resources. */
  destroy(): void;
}

export function createCTModel(study: Study, series: StudySeries): CTModel {
  const studyViewImageData = useStudyViewImageData();

  const maxSliceNumber = computed(() => calculateMaxSliceNumber(series, sliceDirection.value));

  const sliceDirection = ref<CTSliceDirection>(CTSliceDirection.Axial);
  const sliceNumber = ref<number>(Math.floor(maxSliceNumber.value / 2));

  const isLoading = ref(!studyViewImageData.isThreeVolumeForSeriesLoaded(study.id, series.id));
  const loadProgress = ref(0);

  const loadingText = computed(() => {
    if (series.nrrdProcessState === NRRDProcessState.Processing) {
      return "CT series is being processed...";
    }

    return isLoading.value
      ? `Loading ${series.seriesDescription}... ${Math.floor(loadProgress.value * 100)}%`
      : "";
  });

  const clip = series.clips[0];

  let renderer: CTRenderer | null = null;
  let onProvideRenderer: (() => void) | null = null;

  function onStepFrame(delta: number): void {
    sliceNumber.value = Math.max(0, Math.min(sliceNumber.value + delta, maxSliceNumber.value - 1));
  }

  const model = {
    clip,
    series,
    sliceDirection,
    sliceNumber,
    maxSliceNumber,
    isLoading,
    loadingText,

    onStepFrame,
  };

  function provideRenderer(webglRenderer: WebGLRenderer, canvasRect: CanvasContainerRect) {
    renderer = createCTRenderer({
      model,
      webglRenderer,
      canvasRect,
    });

    onProvideRenderer?.();
  }

  function onNRRDLoadProgress(progress: ProgressEvent): void {
    loadProgress.value = progress.loaded / progress.total;
  }

  if (series.nrrdProcessState === NRRDProcessState.Completed) {
    void studyViewImageData
      .getThreeVolumeForSeries(study.id, series.id, onNRRDLoadProgress)
      .then((volume) => {
        onProvideRenderer = () => {
          renderer?.loadVolume(volume);
          isLoading.value = false;
          onProvideRenderer = null;
        };

        if (renderer) {
          onProvideRenderer();
        }
      });
  } else if (series.nrrdProcessState === NRRDProcessState.NotStarted) {
    // Process all NRRD files for the study when they open a CT if they aren't already processed
    for (const seriesToProcess of study.series) {
      if (
        seriesToProcess.nrrdProcessState === NRRDProcessState.NotStarted &&
        isCT3DSeries(seriesToProcess, seriesToProcess.clips[0])
      ) {
        seriesToProcess.nrrdProcessState = NRRDProcessState.Processing;
      }
    }

    void axios.post(`/api/studies/${study.id}/process-nrrd`);
  }

  const unwatchSlicingAndWindowing = watch(
    [sliceNumber, ctSettings.windowLevel, ctSettings.windowWidth],
    () => {
      console.log("triggered");
      renderer?.displayCurrentSlice();
    }
  );

  const unwatchSliceDirection = watch(sliceDirection, (newDirection, oldDirection) => {
    sliceNumber.value =
      (sliceNumber.value / calculateMaxSliceNumber(series, oldDirection)) *
      calculateMaxSliceNumber(series, newDirection);

    renderer?.updateCameraPositionAndOrientation();
    renderer?.displayCurrentSlice();
  });

  function destroy() {
    unwatchSlicingAndWindowing();
    unwatchSliceDirection();
  }

  return { ...model, destroy, provideRenderer };
}

function calculateMaxSliceNumber(series: StudySeries, sliceDirection: CTSliceDirection): number {
  if (sliceDirection === CTSliceDirection.Axial) {
    return series.clips[0].frameCount ?? 0;
  }

  return sliceDirection === CTSliceDirection.Coronal
    ? series.clips[0].height ?? 0
    : series.clips[0].width ?? 0;
}
