<template>
  <div class="back-btn" @click="router.push({ name: 'settings-integrations-dicom-all' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to DICOM Settings
  </div>

  <div class="settings-title">
    DICOM Identity: {{ identity.name }}

    <div style="font-size: 13px; font-weight: normal; display: contents">
      <ToggleSwitch v-model="identity.enabled" @update:model-value="updateIdentityDebounced">
        Enabled
      </ToggleSwitch>

      <button style="margin-left: auto" type="button" @click="deleteDicomClientIdentity">
        Delete Identity
      </button>
    </div>
  </div>

  <div class="field">
    <b>Name</b>
    <input v-model="identity.name" type="text" @update:model-value="updateIdentityDebounced" />
  </div>

  <div class="field">
    <b>AE Title</b>
    <input v-model="identity.aeTitle" type="text" @update:model-value="updateIdentityDebounced" />
  </div>

  <div class="field">
    <b>DICOM TLS</b>
    <ToggleSwitch v-model="identity.tlsAnonymousAllowed" @update:model-value="updateIdentity">
      Allow Anonymous TLS
    </ToggleSwitch>

    <div style="display: flex; gap: 16px">
      <a
        class="download-certificate"
        :download="`${identity.name}.pfx`"
        :href="`data:application/x-pkcs12;base64,${identity.tlsCertificatePfx}`"
      >
        Download Client Certificate
      </a>

      <span> (The password is "<code>pulse</code>") </span>
    </div>
  </div>

  <div class="field">
    <b>Allowed DIMSE Requests</b>
    <ToggleSwitch
      :model-value="identity.allowedDimseRequests.includes(DimseMessage.CStoreRequest)"
      @update:model-value="toggleAllowedDimseMessage(DimseMessage.CStoreRequest)"
    >
      Allow C-STORE-RQ
    </ToggleSwitch>
    <ToggleSwitch
      :model-value="identity.allowedDimseRequests.includes(DimseMessage.CFindRequest)"
      @update:model-value="toggleAllowedDimseMessage(DimseMessage.CFindRequest)"
    >
      Allow C-FIND-RQ
    </ToggleSwitch>
    <ToggleSwitch
      :model-value="identity.allowedDimseRequests.includes(DimseMessage.CGetRequest)"
      @update:model-value="toggleAllowedDimseMessage(DimseMessage.CGetRequest)"
    >
      Allow C-GET-RQ
    </ToggleSwitch>
    <ToggleSwitch
      :model-value="identity.allowedDimseRequests.includes(DimseMessage.NActionRequest)"
      @update:model-value="toggleAllowedDimseMessage(DimseMessage.NActionRequest)"
    >
      Allow N-ACTION-RQ (Storage Commitment)
    </ToggleSwitch>
    <ToggleSwitch :enabled="false" :model-value="false">
      Allow C-MOVE-RQ (not yet supported)
    </ToggleSwitch>
  </div>

  <div class="field">
    <b>History</b>
    <div>
      Created by
      {{ identity.createdByEmail }}
      on
      {{ formatDateTime(identity.createdAt as string) }}
    </div>
    <div>
      Last used:
      {{ formatRelativeTime(identity.lastUsedAt) }}
    </div>
  </div>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import type { DicomClientIdentityGetOneResponseDto } from "@/../../backend/src/dicom/dto/dicom-client-identity-get-one.dto";
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios, { AxiosResponse } from "axios";
import { onMounted, ref } from "vue";
import { DimseMessage } from "../../../../../backend/src/dicom/dimse-message";
import {
  formatDateTime,
  formatRelativeTime,
} from "../../../../../backend/src/shared/date-time-utils";
import ActivityOverlay from "../../../components/ActivityOverlay.vue";
import ToggleSwitch from "../../../components/ToggleSwitch.vue";
import { addNotification } from "../../../utils/notifications";
interface Props {
  id: string;
}

const props = defineProps<Props>();

const activityText = ref("");
const identity = ref<DicomClientIdentityGetOneResponseDto>({
  id: "",
  tenantId: "",
  name: "",
  aeTitle: "",
  tlsCertificatePfx: "",
  tlsCertificateFingerprintSha256: "",
  tlsCertificateExpiresAt: "",
  tlsAnonymousAllowed: false,
  createdById: "",
  createdAt: "",
  lastUsedAt: "",
  enabled: false,
  createdByEmail: "",
  allowedDimseRequests: [],
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<DicomClientIdentityGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<DicomClientIdentityGetOneResponseDto>(
      `/api/dicom-client-identities/${props.id}`
    );
  } catch {
    addNotification({ type: "error", message: "Failed loading identity data" });
    return;
  } finally {
    activityText.value = "";
  }

  identity.value = response.data;
});

const updateIdentityDebounced = useDebounceFn(() => {
  void updateIdentity();
}, 1000);

async function deleteDicomClientIdentity(): Promise<void> {
  if (!confirm(`Are you sure you want to delete client identity "${identity.value.name}"?`)) {
    return;
  }

  activityText.value = "Deleting identity";

  try {
    await axios.delete(`/api/dicom-client-identities/${identity.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Error deleting DICOM client identity" });
    return;
  } finally {
    activityText.value = "";
  }

  await router.push({ name: "settings-integrations-dicom-all" });
  addNotification({ type: "info", message: "Deleted DICOM client identity" });
}

async function updateIdentity(): Promise<void> {
  try {
    await axios.patch(`/api/dicom-client-identities/${identity.value.id}`, identity.value);
  } catch {
    addNotification({ type: "error", message: "Failed updating DICOM client identity" });
    return;
  }

  addNotification({ type: "info", message: "Updated DICOM client identity" });
}

async function toggleAllowedDimseMessage(message: DimseMessage): Promise<void> {
  if (identity.value.allowedDimseRequests.includes(message)) {
    identity.value.allowedDimseRequests = identity.value.allowedDimseRequests.filter(
      (m) => m !== message
    );
  } else {
    identity.value.allowedDimseRequests = [...identity.value.allowedDimseRequests, message];
  }

  await updateIdentity();
}
</script>

<style scoped lang="scss">
.field {
  display: grid;
  gap: 8px;
}

input {
  width: 220px;
}

.download-certificate {
  text-decoration: underline;
}
</style>
