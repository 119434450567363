<template>
  <div v-if="role !== undefined" class="user-role-edit">
    <WarningBlock
      v-if="role.type !== RoleType.TenantControlled"
      data-testid="warning-role-not-editable"
    >
      This role can't be edited because it is controlled by the system
    </WarningBlock>

    <div class="field" :class="{ disabled: !isEditable }">
      <b>Name</b>
      <div style="display: flex; align-items: center">
        <input
          v-model="role.name"
          maxlength="100"
          data-testid="role-name-input"
          @input="saveUserRole"
        />

        <Tooltip
          v-if="hasUserRoleManagePermission && isEditable"
          :content="
            role.isEnabled ? 'This role must be disabled in order to delete it' : 'Delete role'
          "
          style="margin-left: auto"
          placement="left"
        >
          <button
            :disabled="role.isEnabled"
            data-testid="delete-role-button"
            @click="deleteUserRole"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </Tooltip>
      </div>
    </div>

    <div class="field" :class="{ disabled: !isEditable }">
      <div class="toggle">
        <b>Enabled</b>
        <ToggleSwitch
          v-model="role.isEnabled"
          data-testid="role-enabled-toggle"
          @update:model-value="saveUserRole"
        />
      </div>

      <div>
        When this role is disabled no permissions are granted to users who have been assigned the
        role.
      </div>
    </div>

    <div class="field" style="overflow-y: auto">
      <div class="permissions-groups" :class="{ disabled: !isEditable }">
        <div class="column-header">Permission</div>
        <div class="column-header">Details</div>

        <template v-for="group of PERMISSION_GROUPS" :key="group.name">
          <Checkbox
            :model-value="
              !group.permissions.some((permission) => !role?.permissions.includes(permission.value))
            "
            :enabled="isEditable"
            :data-testid="`toggle-all-${group.name}`"
            @update:model-value="onPermissionGroupToggle($event, group)"
          >
            {{ group.name }}
          </Checkbox>

          <div>{{ group.description }}</div>

          <template v-if="group.permissions.length > 1">
            <template v-for="permission in group.permissions" :key="permission.value">
              <div class="permission">
                <Checkbox
                  :model-value="role.permissions.includes(permission.value)"
                  :data-testid="`permission-toggle-${permission.value}`"
                  :enabled="isEditable"
                  @update:model-value="onPermissionToggle($event, permission)"
                >
                  {{ permission.name }}
                </Checkbox>
              </div>

              <div>{{ permission.description }}</div>
            </template>
          </template>
        </template>
      </div>
    </div>
  </div>

  <ActivityOverlay v-if="activityText !== ''" :text="activityText" />
</template>

<script setup lang="ts">
import WarningBlock from "@/components/WarningBlock.vue";
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, onBeforeUnmount, ref, watch } from "vue";
import { RoleType } from "../../../backend/src/user/user-role-type";
import { hasUserRoleManagePermission } from "../auth/authorization";
import { currentTenant } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import Checkbox from "../components/Checkbox.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";
import Tooltip from "../components/Tooltip.vue";
import { addNotification } from "../utils/notifications";
import { useUserRoleList } from "../utils/user-roles-list";
import { PERMISSION_GROUPS, PermissionGroup, PermissionInfo } from "./permission-toggle";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const userRoleList = useUserRoleList();
const role = computed(() => userRoleList.value.find((r) => r.id === props.id));

const activityText = ref("");
const isSaved = ref(true);

let roleIdToSave = "";

watch(
  () => props.id,
  async () => {
    // Save any pending changes to the previously selected role
    await saveUserRoleImmediate();

    roleIdToSave = props.id;
  },
  { immediate: true }
);

const isEditable = computed(
  () => hasUserRoleManagePermission.value && role.value?.type === RoleType.TenantControlled
);

async function saveUserRole(): Promise<void> {
  isSaved.value = false;
  await saveUserRoleDebounced();
}

const saveUserRoleDebounced = useDebounceFn(() => void saveUserRoleImmediate(), 1000);

async function saveUserRoleImmediate(): Promise<void> {
  if (isSaved.value) {
    return;
  }

  const roleToSave = userRoleList.value.find((r) => r.id === roleIdToSave);
  if (roleToSave === undefined) {
    return;
  }

  try {
    await axios.patch(`/api/user-roles/${roleToSave.id}`, {
      isEnabled: roleToSave.isEnabled,
      permissions: roleToSave.permissions,
      name: roleToSave.name.trim(),
    });
  } catch (error) {
    addNotification({ type: "error", message: "Failed saving roles" });
    return;
  }

  addNotification({ type: "info", message: "Updated role" });

  isSaved.value = true;
}

// Save any pending changes on navigating away
onBeforeUnmount(saveUserRoleImmediate);

function onPermissionGroupToggle(newValue: boolean, group: PermissionGroup): void {
  if (role.value === undefined) {
    return;
  }

  for (const permission of group.permissions) {
    if (newValue) {
      role.value.permissions.push(permission.value);

      // Add permissions that are required by this permission
      for (const requiredPermission of permission.requires ?? []) {
        if (!role.value.permissions.includes(requiredPermission)) {
          role.value.permissions.push(requiredPermission);
        }
      }
    } else {
      role.value.permissions = role.value.permissions.filter((p) => p !== permission.value);
    }
  }

  void saveUserRole();
}

function onPermissionToggle(newValue: boolean, permission: PermissionInfo): void {
  if (role.value === undefined) {
    return;
  }

  if (newValue) {
    role.value.permissions.push(permission.value);

    // If this permission was toggled on then also toggle on any permissions it requires
    for (const requiredPermission of permission.requires ?? []) {
      if (!role.value.permissions.includes(requiredPermission)) {
        role.value.permissions.push(requiredPermission);
      }
    }
  } else {
    role.value.permissions = role.value.permissions.filter((p) => p !== permission.value);

    // Remove any other permissions that require the permission that was just toggled off
    for (const group of PERMISSION_GROUPS) {
      for (const permissionInfo of group.permissions) {
        if ((permissionInfo.requires ?? []).includes(permission.value)) {
          role.value.permissions = role.value.permissions.filter((p) => p !== permissionInfo.value);
        }
      }
    }
  }

  void saveUserRole();
}

async function deleteUserRole(): Promise<void> {
  if (role.value === undefined) {
    return;
  }

  if (!confirm(`Are you sure you want to delete the role "${role.value.name}"?`)) {
    return;
  }

  activityText.value = "Deleting role";

  try {
    await axios.delete(`/api/user-roles/${role.value.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed deleting role" });
    return;
  } finally {
    activityText.value = "";
  }

  // Remove role from the role list and the currentTenant.roles value
  currentTenant.roles.splice(
    currentTenant.roles.findIndex((r) => r.id === role.value?.id),
    1
  );
  userRoleList.value.splice(
    userRoleList.value.findIndex((r) => r.id === role.value?.id),
    1
  );

  addNotification({ type: "info", message: "Deleted role" });

  await router.push({ name: "settings-access-roles" });
}
</script>

<style scoped lang="scss">
.user-role-edit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 0;
}

.system-role-message {
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  padding: 12px;
  font-weight: bold;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .toggle {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  input {
    width: 300px;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.permissions-groups {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 8px;

  .column-header {
    top: 0;
    position: sticky;
    background: var(--bg-color-1);
    padding-bottom: 4px;
    font-weight: bold;
  }

  &.disabled {
    opacity: 0.7;
  }
}

.permission {
  padding-left: 32px;
}
</style>
