<template>
  <div v-bind="$attrs" class="clip-viewer" data-testid="clip-viewer">
    <div
      ref="canvasContainerElement"
      class="canvas-container"
      data-testid="canvas-container"
      @mousemove="onCanvasContainerMouseMove"
      @mouseleave="isScrubberAreaHovered = false"
      @mouseup="emits('canvas-container-mouseup', $event)"
      @wheel.prevent="emits('step-frame', $event.deltaY > 0 ? 1 : -1)"
    >
      <slot name="canvases" />

      <div v-if="slots.imageControls" class="image-controls"><slot name="imageControls" /></div>

      <ClipNumberOverlay :clip-number="clipNumber" :clip-count="validClips.length" />

      <Transition name="fade">
        <div v-if="gridItem.isLoading.value || showLoadingIndicator" class="progress-container">
          <LoadingIndicator size="2x" data-testid="clipviewer-loading-spinner" />
          <span v-if="gridItem.loadingText.value !== null">{{ gridItem.loadingText.value }}</span>
        </div>
      </Transition>

      <Transition name="fade">
        <div
          v-if="showScrubber"
          class="controls"
          :class="{
            scrubbing: gridItem.isScrubbing,
          }"
          @mouseup.stop
        >
          <template v-if="gridItem.clip?.fps">
            <slot name="scrubberButton" />

            <div class="scrubber" data-testid="scrubber">
              <DragMove
                enabled
                data-testid="scrubber-dragmove"
                style="width: 100%; height: 100%"
                @start="onScrubberStart"
                @move="onScrubberMove"
                @end="onScrubberEnd"
              >
                <div
                  class="scrubber-progress-bar"
                  data-testid="scrubber-progress"
                  :style="{
                    left: gridItem.scrubberStyles.value.progressBarLeft,
                    width: gridItem.scrubberStyles.value.progressBarWidth,
                  }"
                />

                <slot name="scrubberItems" />

                <div
                  v-if="
                    gridItem.type !== ClipsGridItemType.RegularClip || !gridItem.isPlaying.value
                  "
                  class="scrubber-handle"
                  data-testid="scrubber-handle"
                  :style="{ left: gridItem.scrubberStyles.value.handleLeft }"
                />
              </DragMove>
            </div>
          </template>
          <div v-else style="flex: 1" />
        </div>
      </Transition>

      <slot name="overlays" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useResizeObserver } from "@vueuse/core";
import { computed, onMounted, ref, useSlots, watch } from "vue";
import DragMove from "../../components/DragMove.vue";
import LoadingIndicator from "../../components/LoadingIndicator.vue";
import { activeMeasurement, isMeasuring } from "../../measurements/measurement-tool-state";
import { Study } from "../../utils/study-data";
import ClipNumberOverlay from "../ClipNumberOverlay.vue";
import { getValidClips } from "../study-clip-helpers";
import { CanvasContainerRect } from "./clip-renderer-2d";
import { ClipsGridItem, ClipsGridItemType } from "./clips-grid-item";

interface Props {
  study: Study;
  gridItem: ClipsGridItem;
  canvasHeight: number | undefined;
  canvasWidth: number | undefined;
  clipAspectRatio: number;
  showLoadingIndicator?: boolean;
  canvasRect: CanvasContainerRect;
}

interface Emits {
  (event: "update:canvasRect", canvasRect: CanvasContainerRect): void;
  (event: "set-canvas-dimensions", dims: { width: number; height: number }): void;
  (event: "canvas-container-mouseup", mouseEvent: MouseEvent): void;
  (event: "step-frame", delta: number): void;
  (event: "scrub", xFraction: number): void;
}

const props = withDefaults(defineProps<Props>(), {
  showLoadingIndicator: false,
});
const emits = defineEmits<Emits>();

const slots = useSlots();

const canvasContainerElement = ref<HTMLDivElement | null>(null);

function updateCanvasRect(): void {
  if (canvasContainerElement.value === null) {
    return;
  }

  const containerWidth = canvasContainerElement.value.offsetWidth;
  const containerHeight = canvasContainerElement.value.offsetHeight;

  const containerAspectRatio = containerWidth / containerHeight;

  let canvasWidth = Math.floor(
    props.clipAspectRatio > containerAspectRatio
      ? containerWidth
      : containerHeight * props.clipAspectRatio
  );
  let canvasHeight = Math.floor(
    props.clipAspectRatio > containerAspectRatio
      ? containerWidth / props.clipAspectRatio
      : containerHeight
  );

  emits("set-canvas-dimensions", {
    height: canvasHeight,
    width: canvasWidth,
  });

  // Shift the canvas off the top by 32px when in CT mode so the controls don't overlap any clinical
  // content. The same distance is taken off the width to preserve the canvas aspect ratio.
  const canvasRect = {
    top: Math.floor((containerHeight - canvasHeight) / 2),
    left: Math.floor((containerWidth - canvasWidth) / 2),
    height: canvasHeight,
    width: canvasWidth,
  };

  emits("update:canvasRect", canvasRect);
}

const validClips = computed(() => getValidClips(props.study));

const clipNumber = computed(
  () => validClips.value.findIndex((c) => c.id === props.gridItem.clip?.id) + 1
);

const isScrubberAreaHovered = ref(false);

function onCanvasContainerMouseMove(event: MouseEvent): void {
  // The scrubber area is considered hovered (and therefore made visible) when the mouse is in the
  // lower 100px of the clip
  isScrubberAreaHovered.value =
    event.clientY - props.canvasRect.top >= props.canvasRect.height - 100;
}

//
// Scrubbing and stepping
//

const showScrubber = computed(
  () =>
    (props.gridItem.clip?.frameCount ?? 0) > 1 &&
    (isScrubberAreaHovered.value ||
      props.gridItem.isScrubbing.value ||
      (isMeasuring.value && activeMeasurement.value.studyClipId === props.gridItem.clip?.id))
);

function onScrubberStart(): void {
  const gridItem = props.gridItem;

  gridItem.isScrubbing.value = true;

  if (gridItem.type === ClipsGridItemType.RegularClip) {
    gridItem.isPlaying.value = false;
  }
}

function onScrubberMove({ xFraction }: { xFraction: number }): void {
  emits("scrub", xFraction);
}

function onScrubberEnd(): void {
  const gridItem = props.gridItem;
  gridItem.isScrubbing.value = false;
}

useResizeObserver(canvasContainerElement, updateCanvasRect);
watch(() => [props.clipAspectRatio], updateCanvasRect);

onMounted(() => updateCanvasRect());
</script>

<style scoped lang="scss">
.clip-viewer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  place-self: stretch;
  position: relative;

  &:hover {
    :deep(.clip-number-overlay) {
      opacity: 1;
    }
  }
}

.canvas-container {
  flex: 1.6;
  position: relative;
  background: black;
  display: grid;
}

.progress-container {
  position: absolute;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-weight: bold;
}

.image-controls {
  grid-area: 1 / 1;
  font-size: 0.9em;
  place-self: start center;
  display: flex;
  gap: 6px;
  z-index: 10;
  background: var(--bg-color-1);
  border: 1px solid var(--border-color-1);
  border-top: none;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  padding: 4px 8px;
  align-items: center;

  svg {
    cursor: pointer;
    color: var(--text-color-1);
    transition: color 100ms ease;

    &:hover {
      color: var(--text-color-2);
    }
  }
}

.controls {
  position: absolute;
  z-index: 15;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 6px 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  pointer-events: none;
}

.scrubber {
  grid-area: scrubber;
  flex: 1;
  height: 7px;
  background: var(--bg-color-4);
  border-radius: var(--border-radius);
  position: relative;
  cursor: pointer;
  pointer-events: auto;
}

.scrubber-progress-bar {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--accent-color-1);
  border-radius: var(--border-radius);
  pointer-events: none;
}

.scrubber-handle {
  position: absolute;
  z-index: 5;
  height: 12px;
  width: 12px;
  top: -2px;
  border-radius: 50%;
  background-color: var(--accent-color-1);
  transform: translateX(-6px);
  transition: background-color 250ms ease;
}

.controls.scrubbing,
.scrubber:hover {
  .scrubber-handle {
    background: #ddd;
  }
}
</style>
