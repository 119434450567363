<template>
  <ClipsAreaBase
    v-model:playback-speed-factor="playbackSpeedFactor"
    :selected-clip-ids="selectedClipIds"
    :study="study"
    :clips-grid-items="clipsGridItems"
    :column-count="savedClipsLayout.width"
    :row-count="savedClipsLayout.height"
    :focused-clip-index="focusedClipIndex"
    :is-clip-sync-enabled="isClipSyncEnabled"
    :is-stress-mode-enabled="isStressModeEnabled"
    :show-measurements="showMeasurements"
    :visible-measurement-values-with-contours-count="visibleMeasurementValuesWithContoursCount"
    @update:is-clip-sync-enabled="emits('update:is-clip-sync-enabled', $event)"
    @update:clip-color-map="emits('update:clip-color-map', $event)"
    @update:is-stress-mode-enabled="emits('update:is-stress-mode-enabled', $event)"
    @on-double-click="onClipDoubleClick"
    @on-drop="onDrop"
    @on-drag-over="onDragOver"
    @on-drag-leave="onDragLeave"
    @update:focused-clip-index="emits('update:focused-clip-index', $event)"
    @update:show-measurements="emits('update:show-measurements', $event)"
    @open-measurement-pane="emits('open-measurement-pane')"
    @scroll-to-measurement="
      (measurementId, openMeasurementPane) =>
        emits('scroll-to-measurement', measurementId, openMeasurementPane)
    "
    @highlight-measurement-card="emits('highlight-measurement-card', $event)"
    @measurement-value-hovered="emits('measurement-value-hovered', $event)"
    @measurement-values-show-next="emits('measurement-values-show-next')"
    @measurement-values-show-previous="emits('measurement-values-show-previous')"
  >
    <template #toolbarItems>
      <b v-if="currentClipPage">
        {{ currentClipPage }}
        /
        {{ Math.ceil(activeClips.length / totalSelectedClipsCount) }}
      </b>

      <div class="packed-controls">
        <Tooltip
          :content="selectedClipIds.length > 1 ? 'Previous images' : 'Previous image'"
          shortcut="⇦"
        >
          <button @click="onShowPreviousClips">
            <FontAwesomeIcon icon="chevron-left" data-testid="show-previous-clips-btn" />
          </button>
        </Tooltip>
        <Tooltip :content="selectedClipIds.length > 1 ? 'Next images' : 'Next image'" shortcut="⇨">
          <button @click="onShowNextClips">
            <FontAwesomeIcon icon="chevron-right" data-testid="show-next-clips-btn" />
          </button>
        </Tooltip>

        <Tooltip
          v-if="
            getWindowType() === WindowType.SerialStudy ||
            getSecondaryWindowType() === WindowType.SerialStudy
          "
          content="Toggle multi-window scroll lock"
        >
          <button
            class="outline-when-active"
            :class="{ active: isScrollLockEnabled }"
            style="border-radius: 0 var(--border-radius) var(--border-radius) 0"
            data-testid="scroll-lock"
            @click="isScrollLockEnabled = !isScrollLockEnabled"
          >
            <FontAwesomeIcon :icon="isScrollLockEnabled ? 'link' : 'link-slash'" size="sm" />
          </button>
        </Tooltip>
      </div>

      <Tooltip content="Select layout" shortcut="1 - 9" :visible="!isClipLayoutSelectorOpen">
        <Popper
          class="clip-layout-selector-popper"
          placement="top"
          :offset-distance="6"
          arrow
          interactive
          @open="isClipLayoutSelectorOpen = true"
          @close="isClipLayoutSelectorOpen = false"
        >
          <button
            :class="{ active: isClipLayoutSelectorOpen }"
            data-testid="clip-layout-selector-btn"
          >
            <div class="clip-layout-selector-image">
              <div class="vertical-bar" />
              <div class="horizontal-bar" />
            </div>
          </button>

          <template #content>
            <ClipLayoutSelector
              v-model="savedClipsLayout"
              @update:model-value="onChangeClipsLayout"
            />
          </template>
        </Popper>
      </Tooltip>
    </template>
  </ClipsAreaBase>
</template>

<script setup lang="ts">
import Popper from "@/components/Popper.vue";
import {
  getSecondaryWindowType,
  getWindowType,
  WindowType,
} from "@/study-view/multi-window/secondary-window";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStorage } from "@vueuse/core";
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { getClips } from "../../../backend/src/studies/study-helpers";
import Tooltip from "../components/Tooltip.vue";
import {
  activeMeasurement,
  beginMeasurementSequence,
  isMeasuring,
  setActiveMeasurementSequenceStep,
  startMeasuring,
  stopMeasuring,
} from "../measurements/measurement-tool-state";
import { ColorMap } from "../utils/color-map";
import { onKeyboardShortcut } from "../utils/keyboard-shortcut";
import type { Study } from "../utils/study-data";
import ClipLayoutSelector from "./ClipLayoutSelector.vue";
import ClipsAreaBase from "./ClipsAreaBase.vue";
import { extraClipsWindowClipsLayout, primaryClipsLayout } from "./clip-layouts";
import {
  ClipsGridItem,
  ClipsGridItemType,
  getRegularClipsGridItems,
  useGridItemDragDropEventHandlers,
} from "./clip-viewer/clips-grid-item";
import {
  CrosshairModels,
  crosshairsStore,
  CrosshairsStoreState,
  setActiveCrosshairs,
} from "./ct/ct-crosshairs";
import { CTSliceDirection } from "./ct/ct-model";
import { useMeasurementSequenceList } from "./measurement-sequence-list";
import {
  isHandlingPrimaryWindowMessage,
  onPrimaryWindowMessageReceived,
  postMessageToPrimaryWindow,
} from "./multi-window/primary-window-messages";
import {
  isHandlingSecondaryWindowMessage,
  onSecondaryWindowMessageReceived,
  postMessageToSecondaryWindow,
} from "./multi-window/secondary-window-messages";
import { getClipsSortedByContentTimestamp } from "./study-clip-helpers";
import { useStudyViewImageData } from "./study-view-image-data";

interface Props {
  study: Study;
  clipsGridItems: ClipsGridItem[];
  selectedClipIds: string[];
  focusedClipIndex: number;
  isClipSyncEnabled: boolean;
  isStressModeEnabled: boolean;
  showMeasurements: boolean;
  visibleMeasurementValuesWithContoursCount?: number;
}

interface Emits {
  (event: "update:focused-clip-index", index: number): void;
  (event: "update:clip-color-map", colorMap: ColorMap): void;
  (event: "update:is-clip-sync-enabled", enabled: boolean): void;
  (event: "update:is-stress-mode-enabled", enabled: boolean): void;
  (event: "update:show-measurements", enabled: boolean): void;
  (event: "scroll-clip-list-to-clip", clipId: string): void;
  (event: "open-measurement-pane"): void;
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
  (event: "measurement-values-show-next"): void;
  (event: "measurement-values-show-previous"): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "measurement-value-hovered", measurementValueId: string | null): void;
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const isClipLayoutSelectorOpen = ref(false);

// When viewing multiple clips at once, double-clicking on one of them will switch to only that
// clip being visible (i.e. switches out of multi-clip viewing mode). The previously selected clips
// are stored in this ref so that the original multi-clip selection can then be restored by pressing
// escape or by double-clicking again.
const selectedClipIdsBeforeDoubleClick = ref<string[]>([]);
const selectedClipsLayoutBeforeDoubleClick = ref({ width: 1, height: 1 });

function padSelectedClips(): void {
  const selectedClipIds = props.selectedClipIds;

  while (selectedClipIds.length < savedClipsLayout.value.width * savedClipsLayout.value.height) {
    selectedClipIds.push("");
  }
}

const isScrollLockEnabled = useStorage("scroll-lock-enabled", false);

const playbackSpeedFactor = ref(1);

const savedClipsLayout = computed({
  get() {
    if (getWindowType() === WindowType.ExtraClips) {
      return extraClipsWindowClipsLayout.value;
    }

    return primaryClipsLayout.value;
  },
  set(newValue: { width: number; height: number }) {
    if (getWindowType() === WindowType.ExtraClips) {
      extraClipsWindowClipsLayout.value = newValue;
    } else {
      primaryClipsLayout.value = newValue;
    }
  },
});

function onChangeClipsLayout(layout: { width: number; height: number }): void {
  savedClipsLayout.value = layout;

  const size = layout.width * layout.height;

  const selectedClipIds = props.selectedClipIds;

  if (selectedClipIds.length > size) {
    selectedClipIds.splice(size);

    // Ensure that the focused clip index remains valid when the number of selected clips goes down
    emits("update:focused-clip-index", Math.min(props.focusedClipIndex, size - 1));
  } else if (selectedClipIds.length < size) {
    const clipIdsSorted = activeClips.value;

    // Automatically populate new selections with the clips taken following the last one that's
    // currently selected
    let candidate = clipIdsSorted.indexOf(selectedClipIds.slice(-1)[0]) + 1;
    while (selectedClipIds.length < size && candidate < clipIdsSorted.length) {
      if (!selectedClipIds.includes(clipIdsSorted[candidate])) {
        selectedClipIds.push(clipIdsSorted[candidate] ?? "");
      }

      candidate += 1;
    }

    padSelectedClips();
  }
}

function updateClipLayout(...layouts: { width: number; height: number }[]): void {
  for (const layout of layouts) {
    if (
      savedClipsLayout.value.width !== layout.width ||
      savedClipsLayout.value.height !== layout.height
    ) {
      onChangeClipsLayout(layout);
      return;
    }
  }
}

function fillSelectedClipsFromIndex(firstClipIndex: number): void {
  const clipIdsSorted = activeClips.value;

  const newSelectedClipIds: string[] = [];

  for (let i = 0; i < totalSelectedClipsCount.value; i++) {
    const candidate = firstClipIndex + i;

    if (candidate >= clipIdsSorted.length) {
      break;
    }

    newSelectedClipIds.push(clipIdsSorted[candidate] ?? "");
  }

  // Set the selected clips onto this window
  const selectedClipIds = props.selectedClipIds;
  selectedClipIds.splice(
    0,
    selectedClipIds.length,
    ...newSelectedClipIds.slice(0, selectedClipsCountInThisWindow.value)
  );
  padSelectedClips();

  // Set the remaining selected clips in the secondary window, if one is open
  postMessageToSecondaryWindow({
    type: "set-selected-clips",
    selectedClipIds: newSelectedClipIds.slice(selectedClipsCountInThisWindow.value),
  });
}

const currentClipPage = computed(() => {
  if (props.selectedClipIds.length === 0) {
    return;
  }

  return (
    Math.floor(
      activeClips.value.indexOf(props.selectedClipIds[0]) / totalSelectedClipsCount.value
    ) + 1
  );
});

const selectedClipsCountInThisWindow = computed(
  () => savedClipsLayout.value.width * savedClipsLayout.value.height
);

const totalSelectedClipsCount = computed(() => {
  // If a secondary window showing extra clips is open then the total selected clips count is the
  // sum of the number of clips shown in each window
  if (
    getSecondaryWindowType() === WindowType.ExtraClips ||
    getWindowType() === WindowType.ExtraClips
  ) {
    return (
      primaryClipsLayout.value.width * primaryClipsLayout.value.height +
      extraClipsWindowClipsLayout.value.width * extraClipsWindowClipsLayout.value.height
    );
  }

  return savedClipsLayout.value.width * savedClipsLayout.value.height;
});

const activeClips = computed(() =>
  getClipsSortedByContentTimestamp(getClips(props.study)).map((clip) => clip.id)
);

// When pressing the arrow keys or buttons to move forward/back through the clips for this study,
// we want to make sure that no clips get missed and the user moves forward and back in a
// predictable and consistent way, regardless of which clips are currently selected.
//
// To achieve this, the list of clips is partitioned into groups based on the number of clips
// currently visible at once, and moving forward/back will always move/snap to one of these groups.

function onShowNextClips(): void {
  if (!activeMeasurement.value.isChangeAllowedOf("clip")) {
    return;
  }

  if (getWindowType() === WindowType.ExtraClips) {
    postMessageToPrimaryWindow({ type: "next-clips" });
    return;
  }

  const lastClipIndex = Math.max(
    ...props.selectedClipIds.map((clipId) => activeClips.value.indexOf(clipId))
  );

  // Calculate offset from the first selected clip to the start of the next group. This is then used
  // to calculate where the next selected clips should start from.
  const offset = totalSelectedClipsCount.value - (lastClipIndex % totalSelectedClipsCount.value);
  const nextClipGroupFirstIndex = lastClipIndex + offset;

  const isLastClip = nextClipGroupFirstIndex >= activeClips.value.length;

  // If this is the last clip, wrap around to the beginning of the list
  if (isLastClip) {
    fillSelectedClipsFromIndex(0);
    emits("scroll-clip-list-to-clip", props.selectedClipIds[0]);
  } else {
    fillSelectedClipsFromIndex(nextClipGroupFirstIndex);
    emits("scroll-clip-list-to-clip", getLastSelectedClipId());
  }

  // Inform the other window if scroll lock is on and a serial study window is open
  if (isScrollLockEnabled.value) {
    if (getWindowType() === WindowType.SerialStudy && !isHandlingSecondaryWindowMessage()) {
      postMessageToPrimaryWindow({ type: "next-clips" });
    } else if (
      getSecondaryWindowType() === WindowType.SerialStudy &&
      !isHandlingPrimaryWindowMessage()
    ) {
      postMessageToSecondaryWindow({ type: "next-clips" });
    }
  }
}

function onShowPreviousClips(): void {
  if (!activeMeasurement.value.isChangeAllowedOf("clip")) {
    return;
  }

  if (getWindowType() === WindowType.ExtraClips) {
    postMessageToPrimaryWindow({ type: "prev-clips" });
    return;
  }

  const lastClipIndex = Math.max(
    ...props.selectedClipIds.map((clipId) => activeClips.value.indexOf(clipId))
  );

  // If we're at the beginning of the active clips then wrap around to the end
  if (lastClipIndex < totalSelectedClipsCount.value) {
    const totalClipsCount = activeClips.value.length;
    const lastGroupSize = totalClipsCount % totalSelectedClipsCount.value;

    fillSelectedClipsFromIndex(
      totalClipsCount - (lastGroupSize === 0 ? totalSelectedClipsCount.value : lastGroupSize)
    );
    emits("scroll-clip-list-to-clip", getLastSelectedClipId());
  } else {
    // Move to the start of the previous group of clips
    fillSelectedClipsFromIndex(
      lastClipIndex -
        (lastClipIndex % totalSelectedClipsCount.value) -
        totalSelectedClipsCount.value
    );
    emits("scroll-clip-list-to-clip", props.selectedClipIds[0]);
  }

  if (isScrollLockEnabled.value) {
    if (getWindowType() === WindowType.SerialStudy && !isHandlingSecondaryWindowMessage()) {
      postMessageToPrimaryWindow({ type: "prev-clips" });
    } else if (
      getSecondaryWindowType() === WindowType.SerialStudy &&
      !isHandlingPrimaryWindowMessage()
    ) {
      postMessageToSecondaryWindow({ type: "prev-clips" });
    }
  }
}

function getLastSelectedClipId(): string {
  return [...props.selectedClipIds].reverse().find((id) => id !== "") ?? "";
}

onKeyboardShortcut("ArrowLeft", onShowPreviousClips);
onKeyboardShortcut("ArrowRight", onShowNextClips);

// Pressing the number keys changes the clip layout
onKeyboardShortcut("1", () => updateClipLayout({ width: 1, height: 1 }));
onKeyboardShortcut("2", () => updateClipLayout({ width: 2, height: 1 }, { width: 1, height: 2 }));
onKeyboardShortcut("3", () => updateClipLayout({ width: 3, height: 1 }, { width: 1, height: 3 }));
onKeyboardShortcut("4", () => updateClipLayout({ width: 2, height: 2 }));
onKeyboardShortcut("6", () => updateClipLayout({ width: 3, height: 2 }, { width: 2, height: 3 }));
onKeyboardShortcut("9", () => updateClipLayout({ width: 3, height: 3 }));

function onClipDoubleClick(clipId: string): void {
  // Double-click is currently used when measuring to close an area/volume that's in progress, so
  // disable the double-click to jump to single-clip view if we're measuring or if we're in MPR mode
  // as it doesn't make sense to focus on a single view when we have the 3-view crosshairs open.
  if (isMeasuring.value || crosshairsStore.value.state !== CrosshairsStoreState.Inactive) {
    return;
  }

  const selectedClipIds = props.selectedClipIds;

  // Double-clicking again goes back to the previous state
  if (selectedClipIdsBeforeDoubleClick.value.length !== 0 && selectedClipIds.length === 1) {
    restoreSelectedClipsBeforeDoubleClick();
    return;
  }

  // If there's only one selected clip then double clicking does nothing because we are already
  // showing a single clip, which is what double-clicking achieves
  if (selectedClipIds.length === 1) {
    return;
  }

  // Switch to single-clip view when double-clicking a clip in multi-clip mode, and store the
  // selected clips so they can be restored later
  selectedClipIdsBeforeDoubleClick.value = [...selectedClipIds];
  selectedClipsLayoutBeforeDoubleClick.value = savedClipsLayout.value;

  selectedClipIds.splice(0, selectedClipIds.length, clipId);

  onChangeClipsLayout({ width: 1, height: 1 });
  emits("update:focused-clip-index", 0);
}

function restoreSelectedClipsBeforeDoubleClick(): void {
  if (selectedClipIdsBeforeDoubleClick.value.length === 0) {
    return;
  }

  onChangeClipsLayout(selectedClipsLayoutBeforeDoubleClick.value);

  const selectedClipIds = props.selectedClipIds;
  selectedClipIds.splice(0, selectedClipIds.length, ...selectedClipIdsBeforeDoubleClick.value);

  selectedClipIdsBeforeDoubleClick.value = [];
}

const { onDrop, onDragOver, onDragLeave } = useGridItemDragDropEventHandlers(
  props.clipsGridItems,
  props.selectedClipIds
);

function stepCurrentFrames(delta: number): void {
  for (const item of props.clipsGridItems) {
    item.onStepFrame(delta);
  }

  postMessageToSecondaryWindow({ type: "step-frames", frameStepDelta: delta });
}

watch(
  () => props.study,
  () => {
    if (getWindowType() !== WindowType.ExtraClips) {
      fillSelectedClipsFromIndex(0);
      onChangeClipsLayout(savedClipsLayout.value);
    }

    playbackSpeedFactor.value = 1;
  }
);

//
// CT crosshairs
//

watch(crosshairsStore, async () => {
  if (crosshairsStore.value.state === CrosshairsStoreState.Loading) {
    await setupCTCrosshairs(crosshairsStore.value.seriesId);
  }
});

async function setupCTCrosshairs(seriesId: string): Promise<void> {
  const seriesForCrosshairs = props.study.series.find((series) => series.id === seriesId);

  if (seriesForCrosshairs === undefined) {
    return;
  }

  const selectedClipIds = props.selectedClipIds;
  const fakedClipForSeries = seriesForCrosshairs.clips[0];
  onChangeClipsLayout({ width: 3, height: 1 });
  selectedClipIds.splice(
    0,
    selectedClipIds.length,
    ...[fakedClipForSeries.id, fakedClipForSeries.id, fakedClipForSeries.id]
  );

  // Wait for the selected clip IDs update to propagate upwards so the appropriate clip models
  // have been created in the grid items
  await nextTick();

  const crosshairModels: Partial<CrosshairModels> = {};

  for (let [index, direction] of [
    CTSliceDirection.Axial,
    CTSliceDirection.Coronal,
    CTSliceDirection.Sagittal,
  ].entries()) {
    const item = props.clipsGridItems[index];
    if (item.type !== ClipsGridItemType.CTClip) {
      continue;
    }

    item.sliceDirection.value = direction;
    crosshairModels[item.sliceDirection.value] = item;
  }

  const crosshairVolume = await useStudyViewImageData().getThreeVolumeForSeries(
    props.study.id,
    seriesForCrosshairs.id
  );

  setActiveCrosshairs(seriesForCrosshairs, crosshairModels as CrosshairModels, crosshairVolume);
}

//
// Multi-window handling
//

function setupExtraClipsWindow(): void {
  // If this is an extra clips secondary window then inform the primary window what clips are
  // selected whenever they change so that it can highlight them in the clip list
  watch(
    () => props.selectedClipIds,
    () =>
      postMessageToPrimaryWindow({
        type: "selected-clips-changed",
        selectedClipIds: [...props.selectedClipIds],
      }),
    { deep: true }
  );

  // Update this extra clips secondary window's selected clips when instructed to do so by the
  // primary window
  onSecondaryWindowMessageReceived("set-selected-clips", (message) => {
    // Pad with empty strings if required to make up the numbers
    while (
      message.selectedClipIds.length <
      savedClipsLayout.value.width * savedClipsLayout.value.height
    ) {
      message.selectedClipIds.push("");
    }

    // Replace selected clips
    const selectedClipIds = props.selectedClipIds;
    selectedClipIds.splice(0, selectedClipIds.length, ...message.selectedClipIds);
  });

  // Apply changes to the playback settings made in the primary window
  onSecondaryWindowMessageReceived("set-clip-sync-enabled", (message) =>
    emits("update:is-clip-sync-enabled", message.isClipSyncEnabled)
  );
  onSecondaryWindowMessageReceived(
    "set-playback-speed-factor",
    (message) => (playbackSpeedFactor.value = message.playbackSpeedFactor)
  );
  onSecondaryWindowMessageReceived("set-clips-playing", (message) => {
    for (const item of getRegularClipsGridItems(props.clipsGridItems)) {
      item.isPlaying.value = message.isPlaying;
    }
  });
  onSecondaryWindowMessageReceived("step-frames", (message) =>
    stepCurrentFrames(message.frameStepDelta)
  );

  setupSecondaryWindowMeasurementMessageHandlers();
}

function setupSerialStudyWindow(): void {
  onSecondaryWindowMessageReceived("next-clips", onShowNextClips);
  onSecondaryWindowMessageReceived("prev-clips", onShowPreviousClips);
  onSecondaryWindowMessageReceived("set-clips-playing", (message) => {
    for (const item of getRegularClipsGridItems(props.clipsGridItems)) {
      item.isPlaying.value = message.isPlaying;
    }
  });

  setupSecondaryWindowMeasurementMessageHandlers();
}

const measurementSequenceList = useMeasurementSequenceList();

function setupSecondaryWindowMeasurementMessageHandlers(): void {
  onSecondaryWindowMessageReceived("set-measurement-tool", (message) =>
    message.tool === null
      ? stopMeasuring()
      : startMeasuring({ tool: message.tool, study: props.study, clipId: "" })
  );

  onSecondaryWindowMessageReceived("set-measurement-sequence", (message) => {
    beginMeasurementSequence(props.study, message.sequenceId, measurementSequenceList.value);
    setActiveMeasurementSequenceStep({ stepIndex: message.stepIndex, study: props.study });
  });
}

function setupPrimaryWindow(): void {
  function setSecondaryWindowDefaultSelectedClips(): void {
    const firstSelectedClipIndex =
      Math.max(...props.selectedClipIds.map((clipId) => activeClips.value.indexOf(clipId))) + 1;

    const secondaryWindowSelectedClipIds = activeClips.value.slice(
      firstSelectedClipIndex,
      firstSelectedClipIndex +
        extraClipsWindowClipsLayout.value.width * extraClipsWindowClipsLayout.value.height
    );

    postMessageToSecondaryWindow({
      type: "set-selected-clips",
      selectedClipIds: secondaryWindowSelectedClipIds,
    });
  }

  // When the study changes in the primary window, set the initial selected clips for the secondary
  // window. This is done by finding the index of the last selected clip in the primary window, and
  // then selecting the clips that follow that clip in the secondary window.
  watch(() => props.study, setSecondaryWindowDefaultSelectedClips);

  // Inform the secondary window about any changes to the playback speed settings
  function setSecondaryWindowPlaybackSpeedFactor(): void {
    postMessageToSecondaryWindow({
      type: "set-playback-speed-factor",
      playbackSpeedFactor: playbackSpeedFactor.value,
    });
  }
  watch(playbackSpeedFactor, setSecondaryWindowPlaybackSpeedFactor);

  // Set initial state on a new secondary window
  onPrimaryWindowMessageReceived("secondary-window-opened", () => {
    setSecondaryWindowDefaultSelectedClips();
    setSecondaryWindowPlaybackSpeedFactor();
  });

  onPrimaryWindowMessageReceived("next-clips", onShowNextClips);
  onPrimaryWindowMessageReceived("prev-clips", onShowPreviousClips);

  onPrimaryWindowMessageReceived("set-measurement-tool", (message) =>
    message.tool === null
      ? stopMeasuring()
      : startMeasuring({ tool: message.tool, study: props.study, clipId: "" })
  );

  onPrimaryWindowMessageReceived("set-measurement-sequence", (message) => {
    beginMeasurementSequence(props.study, message.sequenceId, measurementSequenceList.value);
    setActiveMeasurementSequenceStep({ stepIndex: message.stepIndex, study: props.study });
  });
}

// Set up the appropriate watchers, keyboard shortcuts, and cross-window listeners depending on the
// type of window this clips area is in
if (getWindowType() === WindowType.ExtraClips) {
  setupExtraClipsWindow();
} else if (getWindowType() === WindowType.SerialStudy) {
  setupSerialStudyWindow();
} else {
  setupPrimaryWindow();
}

onKeyboardShortcut("Escape", () => {
  if (isMeasuring.value) {
    stopMeasuring();
  } else {
    restoreSelectedClipsBeforeDoubleClick();
  }
});

onMounted(() => {
  fillSelectedClipsFromIndex(0);
  onChangeClipsLayout(savedClipsLayout.value);
});
</script>

<style scoped lang="scss">
.clip-layout-selector-image {
  border-radius: 4px;
  border: 2px solid var(--accent-color-1);
  width: 16px;
  height: 16px;
  position: relative;
  transition: border-color 100ms ease;

  .vertical-bar,
  .horizontal-bar {
    position: absolute;
    background-color: var(--accent-color-1);
    transition: background-color 100ms ease;
  }

  .vertical-bar {
    width: 2px;
    height: 16px;
    left: 7px;
  }

  .horizontal-bar {
    width: 16px;
    height: 2px;
    top: 7px;
  }
}
</style>
