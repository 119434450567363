<template>
  <div class="measurement-cards os-host-flexbox" data-testid="measurements-list">
    <template v-for="measurement in measurements" :key="measurement.id">
      <MeasurementCard
        :id="`measurement-card-${measurement.id}`"
        :is-expanded="isMeasurementCardExpanded[measurement.id]"
        :measurement="measurement"
        :study="study"
        :highlighted-measurement-id="highlightedMeasurementId"
        :hovered-measurement-value-id="hoveredMeasurementValueId"
        :reference-range-set-name="referenceRangeSetName"
        :patient-metrics="patientMetrics"
        :visible-frames="visibleFrames"
        @update:is-expanded="updateMeasurementCardExpanded(measurement.id, $event)"
        @jump-to-value="onJumpToValue"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import MeasurementCard from "@/measurements/MeasurementCard.vue";
import { StudyMeasurement } from "../../../utils/study-data";
import { CommonPaneSectionProps, TabEmits } from "../utils/tabs";

defineProps<CommonPaneSectionProps & { measurements: StudyMeasurement[] }>();

const emits = defineEmits<TabEmits>();

function updateMeasurementCardExpanded(id: string, value: boolean) {
  emits("update:is-measurement-card-expanded", id, value);
}
</script>
