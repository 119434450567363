import type { WebGLRenderer } from "three";
import { OrthographicCamera, Scene } from "three";
import type { VolumeSlice } from "three/examples/jsm/Addons.js";
import type { Volume } from "three/examples/jsm/misc/Volume.js";
import { computed } from "vue";
import type { CTModel } from "./ct-model";
import { CTSliceDirection } from "./ct-model";
import { ctSettings } from "./ct-settings";

export const CT_16_BIT_ZERO_WINDOW_LEVEL = 32768;

/**
 * A CTRenderer is responsible for rendering a CT volume in a Three.js scene. The renderer holds
 * the scene & camera details and manages all aspects of rendering the individual slices to the
 * provided Three WebGLRenderer and hence canvas.
 */
export interface CTRenderer {
  /**
   * Loads a 3D volume created from a NRRD file into the renderer. This will be the 3D object that
   * we take slices from to display.
   */
  loadVolume: (ctVolume: Volume) => void;

  /**
   * Updates the camera position and orientation in response to a change in slice direction.
   */
  updateCameraPositionAndOrientation: () => void;

  /**
   * Extracts the current slice from the volume, removes any currently visible slice and displays
   * it in the scene.
   */
  displayCurrentSlice: () => void;
}

export function createCTRenderer(args: {
  model: Omit<CTModel, "provideRenderer" | "destroy">;
  webglRenderer: WebGLRenderer;
  canvasRect: { top: number; left: number; width: number; height: number };
}): CTRenderer {
  const { model, webglRenderer, canvasRect } = args;

  const camera = new OrthographicCamera();
  const scene = new Scene();

  let volume: Volume | null = null;
  let slice: VolumeSlice | null = null;

  function setupRenderer() {
    scene.add(camera);

    webglRenderer.setPixelRatio(window.devicePixelRatio);
    webglRenderer.setSize(canvasRect.width, canvasRect.height);
    webglRenderer.setAnimationLoop(() => {
      webglRenderer.render(scene, camera);
    });
  }

  const sliceDirectionVectorLetter = computed(() => {
    return {
      [CTSliceDirection.Sagittal]: "x",
      [CTSliceDirection.Coronal]: "y",
      [CTSliceDirection.Axial]: "z",
    }[model.sliceDirection.value];
  });

  function setCameraFrustrum(width: number, height: number) {
    camera.left = -width / 2;
    camera.right = width / 2;
    camera.top = height / 2;
    camera.bottom = -height / 2;
    camera.far = 10000;

    camera.updateProjectionMatrix();
  }

  function updateCameraPositionAndOrientation() {
    const sliceDirection = model.sliceDirection.value;

    // Put the camera behind the volume in whatever direction the slice is. This is so we view the
    // current slice from the correct side - if we were trying to view the volume axially from the
    // top the slices would be flipped left to right, so view them from below instead.
    camera.position.set(
      sliceDirection === CTSliceDirection.Sagittal ? -5000 : 0,
      sliceDirection === CTSliceDirection.Coronal ? -5000 : 0,
      sliceDirection === CTSliceDirection.Axial ? -5000 : 0
    );

    // Rotate the camera back towards the volume so we can see the slice and that it's rotated in
    // the correct direction for this type of slice.
    if (sliceDirection === CTSliceDirection.Sagittal) {
      camera.rotation.set(0.5 * Math.PI, 1.5 * Math.PI, 0);
    } else if (sliceDirection === CTSliceDirection.Coronal) {
      camera.rotation.set(0.5 * Math.PI, 0, 0);
    } else {
      camera.rotation.set(0, Math.PI, 0);
    }

    camera.updateProjectionMatrix();
  }

  // We need to convert the slice number in the LPS coordinate system (i.e. individual slice number)
  // to the RAS coordinate system (which is affected by pixel spacing) as volume.extractSlice
  // expects the slice direction in the RAS coordinate system.
  //
  // See https://github.com/mrdoob/three.js/issues/24920
  function convertSliceNumberToRASDimension(sliceNumber: number): number {
    if (volume === null) {
      return 0;
    }

    const dimension = ["x", "y", "z"].indexOf(sliceDirectionVectorLetter.value);

    const maxDimension = volume.RASDimensions[dimension];
    const spacingAdjustedSlice = sliceNumber * volume.spacing[dimension];

    // Because we're looking at the volume from the back, we need to get the slice with that RAS
    // index against the -ve slice axis instead of +ve, so we need to subtract the slice number
    // from the max dimension.
    return maxDimension - spacingAdjustedSlice;
  }

  function displayCurrentSlice() {
    if (volume === null) {
      return;
    }

    if (slice !== null) {
      scene.remove(slice.mesh);
    }

    volume.windowLow =
      ctSettings.windowLevel.value - ctSettings.windowWidth.value / 2 + CT_16_BIT_ZERO_WINDOW_LEVEL;
    volume.windowHigh =
      ctSettings.windowLevel.value + ctSettings.windowWidth.value / 2 + CT_16_BIT_ZERO_WINDOW_LEVEL;

    const rasSliceNumber = convertSliceNumberToRASDimension(model.sliceNumber.value);

    slice = volume.extractSlice(sliceDirectionVectorLetter.value, rasSliceNumber);
    scene.add(slice.mesh);
  }

  function loadVolume(ctVolume: Volume) {
    volume = ctVolume;

    setCameraFrustrum(volume.RASDimensions[0], volume.RASDimensions[1]);
    updateCameraPositionAndOrientation();
    displayCurrentSlice();
  }

  setupRenderer();

  return {
    loadVolume,
    updateCameraPositionAndOrientation,
    displayCurrentSlice,
  };
}
