import { formatDicomName } from "@/../../backend/src/shared/dicom-helpers";
import { v4 as uuidv4 } from "uuid";
import type { CSSProperties } from "vue";
import type { PatientMetrics } from "../../../backend/src/measurements/measurement-display";
import {
  getMeasurementDisplayName,
  getPatientMetrics,
  getStudyMeasurementDisplayValue,
} from "../../../backend/src/measurements/measurement-display";
import { type MeasurementName } from "../../../backend/src/measurements/measurement-names";
import type {
  ReportSectionStructure,
  ReportSectionStructuredField,
  ReportStructure,
} from "../../../backend/src/reporting/report-structure";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";
import {
  StudyMeasurementDisplayOption,
  StudyMeasurementValueSource,
} from "../../../backend/src/studies/study-measurement-enums";
import { StudyReportType } from "../../../backend/src/studies/study-report-type";
import { getStudyDateTime } from "../../../backend/src/studies/study-time";
import {
  hasStudyReportAmendmentCompleteWithUpdatedContentPermission,
  hasStudyReportPreliminaryFinalizePermission,
} from "../auth/authorization";
import { currentTenant, currentUser } from "../auth/current-session";
import { isMeasurementIndexable } from "../measurements/measurement-helpers";
import { getPatientSexDisplayText } from "../utils/patient-data";
import {
  getLatestReport,
  getPatientAgeWhenScanned,
  getSortedReports,
  type Study,
  type StudyMeasurement,
  type StudyReport,
} from "../utils/study-data";
import type { UserListEntry } from "../utils/users-list";
import { evaluateTextContainingCalculations } from "./report-calculation";
import type { CalculationScope } from "./report-calculation-scope";

export enum ReportContentMode {
  GenerateHTML = "generateHtml",
  EditReportContent = "editReportContent",
  ViewReportStructure = "viewReportStructure",
  EditReportStructure = "editReportStructure",
}

export function getTopBoxDetails(study: Study): { key: string; value: string }[] {
  const patientMetrics = getPatientMetrics(study);

  return [
    {
      key: "Patient Name",
      value: formatDicomName(study.patientName, currentTenant.patientNameFormat),
    },
    { key: currentTenant.patientIdLabel, value: study.patientId },
    {
      key: "DOB",
      value: formatDateTime(study.patientBirthdate),
    },
    { key: "Age", value: getPatientAgeWhenScanned(study) ?? "" },
    { key: "Sex", value: getPatientSexDisplayText(study.patientSex) },
    { key: "Ethnicity", value: study.patientEthnicity },
    { key: "Height", value: patientMetrics ? `${patientMetrics.height.toFixed(2)}m` : "" },
    { key: "Weight", value: patientMetrics ? `${patientMetrics.weight.toFixed(1)}kg` : "" },
    {
      key: "BSA",
      value: patientMetrics ? `${patientMetrics.bodySurfaceArea.toFixed(2)}m²` : "",
    },
  ];
}

export function getBottomBoxDetails(
  study: Study,
  reportedAt: string,
  userList: UserListEntry[]
): { key: string; value: string }[] {
  // Find the name of the user who approved the preliminary report, if any
  const preliminaryReport = getSortedReports(study.reports).find(
    (report) => report.completedAt !== null && report.type === StudyReportType.Preliminary
  );
  const preliminaryReportApprovedBy = userList.find(
    (u) => u.id === preliminaryReport?.completedById
  )?.name;

  const reporterId = getLatestReportReporterUserId(study);

  const trainee = userList.find((u) => u.id === study.traineeUserId)?.name;
  const technician = userList.find((u) => u.id === study.technicianUserId)?.name;
  const physician = userList.find((u) => u.id === study.physicianUserId)?.name;
  const reporter = userList.find((u) => u.id === reporterId)?.name;

  return [
    {
      key: "Study date",
      value: formatDateTime(getStudyDateTime(study)),
    },
    { key: "Referred by", value: formatDicomName(study.referringPhysician) },
    { key: "Performed by", value: formatDicomName(study.performingPhysician) },
    { key: "Reported on", value: reportedAt },
    { key: "Reported by", value: reporter ?? "" },
    { key: "Preliminary report approved by", value: preliminaryReportApprovedBy ?? "" },
    { key: currentTenant.traineeLabel, value: trainee ?? "" },
    { key: currentTenant.technicianLabel, value: technician ?? "" },
    { key: currentTenant.physicianLabel, value: physician ?? "" },
  ];
}

/**
 * Returns the ID of the user who should be considered the reporter of the latest report for
 * the given study. This is needed because it is not always the current user, as in the case of
 * a user who can complete report amendments but not change the report content in the process,
 * the name of the user who signed the final report should be put as the reporter for the amendment.
 */
export function getLatestReportReporterUserId(study: Study): string | null {
  const finalReport = getSortedReports(study.reports).find(
    (report) => report.type === StudyReportType.Final
  );

  if (finalReport !== undefined) {
    return finalReport.completedById;
  }

  const latestReport = getLatestReport(study.reports);
  if (
    (latestReport?.type === StudyReportType.Preliminary &&
      hasStudyReportPreliminaryFinalizePermission.value) ||
    (latestReport?.type === StudyReportType.Amendment &&
      hasStudyReportAmendmentCompleteWithUpdatedContentPermission.value)
  ) {
    return currentUser.id;
  }

  return null;
}

/**
 * Returns whether the given section heading should be shown on the final report PDF. Section
 * headings are only included if one of the sections under them has content, otherwise the heading
 * is left out of the final report.
 */
export function isSectionHeadingVisibleOnFinalReport(
  study: Study,
  report: StudyReport,
  scope: CalculationScope,
  sectionId: string
): boolean {
  const sections = report.reportTemplateVersion.structure.sections;
  const sectionIndex = sections.findIndex((s) => s.id === sectionId);

  if (sectionIndex === -1) {
    return false;
  }

  for (let i = sectionIndex + 1; i < sections.length; i++) {
    // If we've hit the next heading then stop looking for content
    if (sections[i].type === "heading") {
      break;
    }

    // If this section has content on the final report then the heading should be visible
    if (isSectionReported(study, report, scope, sections[i].id)) {
      return true;
    }
  }

  return false;
}

/**
 * Returns whether the specified section has any editable content in it. Sections are editable if
 * their comment field is turned on or they have any fields that the user can set a value for.
 */
export function isSectionEditable(structure: ReportStructure, sectionId: string): boolean {
  const section = structure.sections.find((s) => s.id === sectionId);
  if (!section) {
    return false;
  }

  return (
    section.type !== "heading" &&
    (section.isCommentFieldVisible || section.structuredFieldColumns.flat().some(isFieldEditable))
  );
}

/** Returns whether the specified field is editable when reporting. */
export function isFieldEditable(field: ReportSectionStructuredField): boolean {
  return field.type === "dropdown" || (field.type === "text" && !isStaticTextField(field));
}

/**
 * Returns whether the given section has had any content reported for it, i.e. have any of its
 * fields been filled in, or a comment been added.
 */
export function isSectionReported(
  study: Study,
  report: StudyReport,
  scope: CalculationScope,
  sectionId: string
): boolean {
  return (
    getFinalReportSectionText(report, sectionId, scope) !== "" ||
    getFinalReportSectionFields(study, report, scope, sectionId).flat().length !== 0
  );
}

export type FinalReportSectionField = ReportSectionStructuredField & { value: string };

/**
 * Returns the set of named fields to show for this section on the final report, grouped by column.
 */
export function getFinalReportSectionFields(
  study: Study,
  report: StudyReport,
  scope: CalculationScope,
  sectionId: string
): FinalReportSectionField[][] {
  const section = report.reportTemplateVersion.structure.sections.find((s) => s.id === sectionId);
  if (!section) {
    return [];
  }

  const result: FinalReportSectionField[][] = [];

  for (const column of section.structuredFieldColumns) {
    result.push([]);

    for (const field of column) {
      const value = getReportFieldDisplayText(study, report, scope, sectionId, field);

      // If there is no text for this field and field compaction is disabled then don't emit it
      if (value.length === 0 && section.isFieldCompactionEnabled) {
        continue;
      }

      result[result.length - 1].push({ ...field, value });
    }
  }

  return result;
}

/** Returns the final text value to display on the report for the given field. */
export function getReportFieldDisplayText(
  study: Study,
  report: StudyReport,
  scope: CalculationScope,
  sectionId: string,
  field: ReportSectionStructuredField
): string {
  const sectionContent = report.content.sections[sectionId];

  let text = "";

  if (field.type === "text") {
    const fieldContent = sectionContent.structuredFields[field.id];

    if (isTextbox(field) || fieldContent.isTextOverriden) {
      text = sectionContent.structuredFields[field.id].text;
    } else {
      text = evaluateTextContainingCalculations(field.text, scope, { erroredCalculationValue: "" });
    }
  } else if (field.type === "dropdown" && !field.isSentence) {
    text = sectionContent.structuredFields[field.id].optionIds
      .map((optionId) => field.options.find((o) => o.id === optionId))
      .filter((option) => option !== undefined && option.name !== "")
      .map((option) =>
        option?.id === "custom" ? sectionContent.structuredFields[field.id].text : option?.name
      )
      .filter(Boolean)
      .join(", ");
  } else if (field.type === "measurement") {
    const fieldContent = sectionContent.structuredFields[field.id];

    if (fieldContent.isTextOverriden) {
      text = sectionContent.structuredFields[field.id].text;
    } else {
      const measurement = study.measurements.find((m) => m.name === field.measurementName);

      text =
        getStudyMeasurementDisplayValue(
          measurement,
          field.isIndexed ? "indexed" : "unindexed",
          getPatientMetrics(study)
        )?.fullText ?? "";
    }
  }

  return text.trim();
}

/**
 * Generates the final text for a report section. This goes through all the section's sentence
 * fields and combines their sentences together with the freetext comment.
 */
export function getFinalReportSectionText(
  report: StudyReport,
  sectionId: string,
  scope: CalculationScope,
  includeComment = true
): string {
  const section = report.reportTemplateVersion.structure.sections.find((s) => s.id === sectionId);
  if (!section) {
    console.warn("getFinalReportSectionText() - Section content is missing");
    return "";
  }

  const sectionContent = report.content.sections[sectionId];

  const result: string[] = [];

  for (const column of section.structuredFieldColumns) {
    for (const field of column) {
      if (field.type !== "dropdown" || !field.isSentence) {
        continue;
      }

      for (const selectedOptionId of sectionContent.structuredFields[field.id].optionIds) {
        let sentence = "";

        if (selectedOptionId === "custom") {
          sentence = sectionContent.structuredFields[field.id].text;
        } else {
          sentence = field.options.find((o) => o.id === selectedOptionId)?.sentence ?? "";
        }

        sentence = sentence.trim();
        if (sentence.length !== 0) {
          result.push(sentence.endsWith(".") ? sentence : `${sentence}.`);
        }
      }
    }
  }

  // Add free text comment, if any
  if (includeComment && sectionContent.comment.trim().length > 0) {
    result.push(evaluateTextContainingCalculations(sectionContent.comment.trim(), scope));
  }

  return result.join(" ");
}

export interface ReportMeasurement {
  measurement: StudyMeasurement;
  displayName: string;
  value: string;
  indexed: boolean;
}

export function getReportMeasurementsForStudy(
  measurements: StudyMeasurement[],
  structure: ReportStructure,
  patientMetrics: PatientMetrics | undefined,
  useFakeValuesForMissingMeasurements: boolean
): {
  name: string;
  columns: ReportMeasurement[][];
  flattened?: (ReportMeasurement | null)[];
  compacted?: ReportMeasurement[];
}[] {
  const measurementGroups = structure.measurementGroups;

  const unreportedMeasurements = [...measurements];

  const result: { name: string; columns: ReportMeasurement[][] }[] = [];

  for (const measurementGroup of measurementGroups) {
    const resultColumns: ReportMeasurement[][] = [];

    for (const column of measurementGroup.columns) {
      const measurementLines: ReportMeasurement[] = [];

      for (const measurement of column) {
        // See if this measurement exists in the study
        let studyMeasurement = measurements.find((m) => m.name === measurement.name);

        // If faking of measurement values is turned on then use a fake value (for debugging only)
        if (studyMeasurement === undefined && useFakeValuesForMissingMeasurements) {
          studyMeasurement = getFakeStudyMeasurement(measurement.name);
        }

        if (studyMeasurement === undefined) {
          continue;
        }

        measurementLines.push(
          ...getReportMeasurementsForStudyMeasurement(studyMeasurement, patientMetrics)
        );

        // Remove this measurement from the unreported list
        const index = unreportedMeasurements.indexOf(studyMeasurement);
        if (index !== -1) {
          unreportedMeasurements.splice(index, 1);
        }
      }

      resultColumns.push(measurementLines);
    }

    // If all columns are empty then don't show the section
    if (resultColumns.some((column) => column.length > 0)) {
      result.push({ name: measurementGroup.name, columns: resultColumns });
    }
  }

  // Any measurements that weren't covered in the above loop are added to an 'Other Measurements'
  // group
  if (unreportedMeasurements.length > 0) {
    unreportedMeasurements.sort((a, b) => a.name.localeCompare(b.name));

    const columns: ReportMeasurement[][] = [[], [], []];
    for (let i = 0; i < unreportedMeasurements.length; i++) {
      columns[i % 3].push(
        ...getReportMeasurementsForStudyMeasurement(unreportedMeasurements[i], patientMetrics)
      );
    }

    result.push({ name: "Other Measurements", columns });
  }

  return result;
}

function getFakeStudyMeasurement(measurementName: MeasurementName): StudyMeasurement {
  return {
    id: "",
    studyId: "",
    name: measurementName,
    customName: "",
    customUnit: null,
    values: [
      {
        id: "",
        measurementTool: null,
        measurementCreationBatchId: uuidv4(),
        calculationFormula: null,
        calculationOutputUnit: null,
        calculationVariables: null,
        calculationInputs: [],
        value: 99,
        source: StudyMeasurementValueSource.Manual,
        selected: true,
        frame: 0,
        contour: [],
        studyClipId: "",
        createdById: "",
        lastUpdatedById: "",
        measurementId: "",
        createdAt: null,
        apiKeyName: null,
        lastUpdatedAt: null,
      },
    ],
    displayOption: StudyMeasurementDisplayOption.PreferIndexed,
  };
}

function getReportMeasurementsForStudyMeasurement(
  measurement: StudyMeasurement,
  patientMetrics: PatientMetrics | undefined
): ReportMeasurement[] {
  let showUnindexedValue = true;
  let showIndexedValue = false;

  const isIndexedValueKnown =
    isMeasurementIndexable(measurement.name) && patientMetrics !== undefined;

  if (isIndexedValueKnown) {
    if (measurement.displayOption === StudyMeasurementDisplayOption.PreferIndexed) {
      showUnindexedValue = false;
      showIndexedValue = true;
    } else if (measurement.displayOption === StudyMeasurementDisplayOption.UnindexedAndIndexed) {
      showIndexedValue = true;
    }
  }

  const result: ReportMeasurement[] = [];

  if (showUnindexedValue) {
    const displayValue = getStudyMeasurementDisplayValue(measurement, "unindexed");
    if (displayValue) {
      result.push({
        measurement,
        displayName: getMeasurementDisplayName(measurement, "unindexed"),
        value: displayValue.fullText,
        indexed: false,
      });
    }
  }

  if (showIndexedValue) {
    const displayValue = getStudyMeasurementDisplayValue(measurement, "indexed", patientMetrics);
    if (displayValue) {
      result.push({
        measurement,
        displayName: getMeasurementDisplayName(measurement, "indexed"),
        value: displayValue.fullText,
        indexed: true,
      });
    }
  }

  return result;
}

export function isTextbox(field: ReportSectionStructuredField): boolean {
  return field.type === "text" && field.text === "" && field.isEditable;
}

export function isStaticTextField(field: ReportSectionStructuredField): boolean {
  return field.type === "text" && field.text !== "" && !field.isEditable;
}

/** Returns the sentence groups to show in the reporting UI for the specified section. */
export function getSentenceGroupsForSection(
  structure: ReportStructure,
  sectionId: string
): { id: string; name: string; sentences: string[] }[] {
  const section = structure.sections.find((s) => s.id === sectionId);
  if (section === undefined) {
    return [];
  }

  return section.sentenceGroupIds.map((id) => ({ ...structure.sentenceGroups[id], id }));
}

/**
 * Details for events emitted by the comment field in a report section. These events are used to
 * manage display of the sentence library content next to the reporting pane.
 */
export type ReportSectionCommentFieldEventDetails = { sectionId: string } & (
  | { action: "click-out" }
  | { action: "focus"; focusTextarea: () => void; insertText: (value: string) => void }
);

export function getFieldStylingCSS(field: ReportSectionStructuredField): CSSProperties {
  // Don't style sentence fields
  if (field.type === "dropdown" && field.isSentence) {
    return {};
  }

  return {
    fontWeight: field.styling.bold ? "bold" : "normal",
    fontStyle: field.styling.italic ? "italic" : "normal",
    color: field.styling.color,
  };
}

export function getFieldAlignmentCSS(
  section: ReportSectionStructure,
  field: FinalReportSectionField | ReportSectionStructuredField
): CSSProperties {
  if (section.type !== "table" || field.cellAlignment === undefined) {
    return {
      textAlign: "left",
      alignSelf: "start",
    };
  }

  return {
    textAlign: field.cellAlignment.horizontal,
    alignSelf: { top: "start", center: "center", bottom: "end" }[field.cellAlignment.vertical],
  };
}
