<template>
  <BaseClipViewer
    :canvas-rect="canvasRect"
    :study="study"
    :grid-item="gridItem"
    :canvas-height="canvasRect.height"
    :canvas-width="canvasRect.width"
    :clip-aspect-ratio="gridItem.clip ? gridItem.clip.width! / gridItem.clip.height! : 1"
    :show-loading-indicator="renderer?.isLoadingSpinnerVisible.value"
    :data-test-current-frame="renderer?.currentlyVisibleFrame.value"
    :data-test-playing="gridItem.isPlaying.value"
    :data-test-brightness="renderer?.brightness.value"
    :data-test-contrast="renderer?.contrast.value"
    @update:canvas-rect="updateCanvasRect"
    @set-canvas-dimensions="setCanvasDimensions"
    @canvas-container-mouseup="renderer?.onCanvasContainerMouseUp"
    @step-frame="onStepFrame"
    @scrub="onScrub"
  >
    <template #canvases>
      <svg v-if="gridItem.clip">
        <filter :id="`filter-${gridItem.clip.id}`" color-interpolation-filters="sRGB">
          <feColorMatrix
            type="matrix"
            :values="colorMapMatrix[gridItem.colorMap.value ?? ColorMap.Grey]"
            data-testid="clip-canvas-filter"
          />
        </filter>
      </svg>

      <canvas
        ref="canvasElement"
        :style="{
          top: `${canvasRect.top.toFixed(0)}px`,
          left: `${canvasRect.left.toFixed(0)}px`,
          width: `${canvasRect.width.toFixed(0)}px`,
          height: `${canvasRect.height.toFixed(0)}px`,
          filter: `contrast(${renderer?.contrast.value ?? 1}) brightness(${renderer?.brightness.value ?? 1})`,
        }"
      />
      <canvas
        ref="measurementCanvasElement"
        data-testid="canvas"
        :style="{
          top: `${canvasRect.top.toFixed(0)}px`,
          left: `${canvasRect.left.toFixed(0)}px`,
          width: `${canvasRect.width.toFixed(0)}px`,
          height: `${canvasRect.height.toFixed(0)}px`,
        }"
        @mousedown="onCanvasMouseDown"
        @mousemove="onCanvasMouseMove"
        @contextmenu.prevent="onCanvasContextMenu"
      />
    </template>

    <template v-if="renderer?.isBrightnessContrastTextVisible.value === true" #imageControls>
      <Tooltip content="Reset brightness and contrast">
        <FontAwesomeIcon
          icon="times"
          data-testid="brightness-contrast-reset"
          @click="renderer?.onBrightnessContrastReset"
        />
      </Tooltip>

      <span>
        <strong>Brightness</strong>: {{ ((renderer?.brightness.value ?? 1) * 100).toFixed(0) }}%
      </span>
      <span>
        <strong>Contrast</strong>: {{ ((renderer?.contrast.value ?? 1) * 100).toFixed(0) }}%
      </span>
    </template>

    <template #scrubberButton>
      <div
        class="play-pause-button"
        data-testid="play-pause-button"
        @click="emits('play-pause-button-click')"
      >
        <FontAwesomeIcon :icon="gridItem.isPlaying.value ? 'pause' : 'play'" />
      </div>
    </template>

    <template #scrubberItems>
      <ScrubberHeartbeats v-if="gridItem.heartbeats.length < 10" :clip-model="gridItem" />

      <ScrubberMeasurementIndicators :study="study" :clip-model="gridItem" />
    </template>

    <template #overlays>
      <ClipViewerMeasurementLabels
        v-if="
          canvasElement !== null &&
          gridItem.clip?.id !== undefined &&
          (showMeasurements || isMeasuring)
        "
        :study="study"
        :study-clip-id="gridItem.clip?.id"
        :measurement-labels="renderer?.measurementsRenderer.measurementLabels.value ?? []"
        :canvas-element="canvasElement"
        :canvas-rect="canvasRect"
        @scroll-to-measurement="
          (measurementId, openMeasurementPane) =>
            emits('scroll-to-measurement', measurementId, openMeasurementPane)
        "
        @highlight-measurement-card="emits('highlight-measurement-card', $event)"
        @measurement-value-hovered="emits('measurement-value-hovered', $event)"
      />
    </template>
  </BaseClipViewer>
</template>

<script setup lang="ts">
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import Tooltip from "../../components/Tooltip.vue";
import { activeMeasurement, isMeasuring } from "../../measurements/measurement-tool-state";
import { ColorMap, colorMapMatrix } from "../../utils/color-map";
import { Study } from "../../utils/study-data";
import ClipViewerMeasurementLabels from "../ClipViewerMeasurementLabels.vue";
import BaseClipViewer from "./BaseClipViewer.vue";
import ScrubberHeartbeats from "./ScrubberHeartbeats.vue";
import ScrubberMeasurementIndicators from "./ScrubberMeasurementIndicators.vue";
import { CanvasContainerRect, ClipRenderer2D, create2DClipRenderer } from "./clip-renderer-2d";
import { RegularClipsGridItem } from "./clips-grid-item";

interface Props {
  study: Study;
  gridItem: RegularClipsGridItem;
  showMeasurements: boolean;
  isComparisonViewer?: boolean;
}

interface Emits {
  (event: "mousedown", onHandled: () => void, isContextMenu: boolean): void;
  (event: "play-pause-button-click"): void;
  (event: "scrub", newTime: number): void;
  (event: "scroll-to-measurement", measurementId: string, openMeasurementPane: boolean): void;
  (event: "highlight-measurement-card", measurementId: string): void;
  (event: "measurement-value-hovered", measurementValueId: string | null): void;
}

const props = withDefaults(defineProps<Props>(), {
  isComparisonViewer: false,
});
const emits = defineEmits<Emits>();

const canvasElement = ref<HTMLCanvasElement | null>(null);
const measurementCanvasElement = ref<HTMLCanvasElement | null>(null);

const canvasRect = ref({ top: 0, left: 0, width: 0, height: 0 });

let renderer: ClipRenderer2D | null = null;

const showMeasurements = computed(() => props.showMeasurements);

function onCanvasMouseDown(event: MouseEvent): void {
  emits("mousedown", () => renderer?.onCanvasMouseDown(event), false);
}

function onCanvasMouseMove(event: MouseEvent): void {
  renderer?.onCanvasMouseMove(event);
}

// Start a distance measurement on right click
function onCanvasContextMenu(evt: MouseEvent): void {
  // Don't allow right-click distance on comparison views
  if (!props.isComparisonViewer) {
    emits("mousedown", () => renderer?.onCanvasContextMenu(evt), true);
  }
}

function updateCanvasRect(rect: CanvasContainerRect): void {
  canvasRect.value = rect;

  if (renderer) {
    renderer.currentlyVisibleFrame.value = undefined;
    renderer.drawCurrentFrame();
  }
}

function setCanvasDimensions(dims: { width: number; height: number }): void {
  if (canvasElement.value === null || measurementCanvasElement.value === null) {
    return;
  }

  canvasElement.value.width = dims.width;
  canvasElement.value.height = dims.height;

  measurementCanvasElement.value.width = dims.width;
  measurementCanvasElement.value.height = dims.height;
}

function onScrub(xFraction: number): void {
  emits("scrub", Math.floor(xFraction * (props.gridItem.clipDuration - 1)));

  // eslint-disable-next-line vue/no-mutating-props
  props.gridItem.soloMeasurementValueId.value = undefined;
}

function onStepFrame(delta: number): void {
  const gridItem = props.gridItem;

  // Don't change the frame if they've already started placing points
  if (activeMeasurement.value.isChangeAllowedOf("frame")) {
    gridItem.isPlaying.value = false;
    gridItem.stepCurrentTime(delta * props.gridItem.frameDuration);
  }

  gridItem.soloMeasurementValueId.value = undefined;
}

function createRenderer() {
  const canvas = canvasElement.value;
  const measurementCanvas = measurementCanvasElement.value;
  const ctx = canvas?.getContext("2d") ?? null;
  const measurementCtx = measurementCanvas?.getContext("2d") ?? null;

  if (canvas === null || ctx === null || measurementCanvas === null || measurementCtx === null) {
    return;
  }

  renderer?.onUnmount();
  renderer = create2DClipRenderer({
    study: props.study,
    model: props.gridItem,
    canvas,
    measurementCanvas,
    ctx,
    measurementCtx,
    togglePlayPause: () => emits("play-pause-button-click"),
    showMeasurements,
  });
}

watch(() => props.gridItem, createRenderer);

onMounted(() => createRenderer());
onUnmounted(() => renderer?.onUnmount());
</script>

<style scoped lang="scss">
canvas {
  position: absolute;
}

.play-pause-button {
  flex: 0 0 22px;
  height: 22px;
  width: 22px;
  border-radius: var(--border-radius);
  background-color: var(--bg-color-2);
  color: var(--accent-color-1);
  transition:
    background-color 100ms ease,
    color 100ms ease;
  cursor: pointer;
  display: grid;
  place-content: center;
  pointer-events: auto;

  &:hover,
  &.active {
    background-color: var(--bg-color-3);
    color: var(--accent-color-2);
  }
}

.ct-mode-wrapper {
  grid-area: 1 / 1;
  z-index: 3;
}

.ct-mode-btn {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--accent-color-1);
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  border: 1px solid var(--accent-color-1);
  height: 12px;
  width: 60px;
  padding: 4px 8px;
  margin: 8px 8px 8px auto;

  &:hover,
  &.selected {
    color: var(--accent-color-2);
    background-color: var(--bg-color-3);
  }

  &.selected {
    border: 1px solid var(--accent-color-2);
  }
}
</style>
