import { useRafFn, useTimeoutFn } from "@vueuse/core";
import type { ComputedRef, Ref } from "vue";
import { computed, ref, watch } from "vue";
import { positiveModulo } from "../../../../backend/src/shared/math-utils";
import { activeMeasurement, isMeasuring } from "../../measurements/measurement-tool-state";
import { ColorMap } from "../../utils/color-map";
import { isPlaywrightTest } from "../../utils/e2e-test-utils";
import type { Study } from "../../utils/study-data";
import type { ClipModel } from "../clip-model";
import type { MeasurementsController } from "./measurements-controller";
import { createMeasurementsController } from "./measurements-controller";
import type { MeasurementsRenderer } from "./measurements-renderer";
import { createMeasurementsRenderer } from "./measurements-renderer";

/**
 * Represents the position of a canvas inside it's container
 */
export interface CanvasContainerRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

/**
 * A ClipRenderer2D is responsible for rendering a 2D clip in a canvas element. It handles drawing
 * the current frame webp when needed, forwards canvas mouse events to the relevant controller and
 * manages other state changes, such as brightness or contrast. The output of a ClipRenderer2D is
 * primarily a function dependant on the current state of the ClipModel along with any brightness or
 * contrast adjustments.
 */
export interface ClipRenderer2D {
  /** The adjustment to the clip brightness. Will be 1 if there is no change. */
  brightness: Ref<number>;

  /** The adjustment to the clip contrast. Will be 1 if there is no change. */
  contrast: Ref<number>;

  /** Whether the brightness/contrast text should be visible. */
  isBrightnessContrastTextVisible: ComputedRef<boolean>;

  /**
   * The measurements renderer for this clip, which is responsible for drawing the measurement
   * annotations onto the clip canvas.
   */
  measurementsRenderer: MeasurementsRenderer;

  /**
   * The measurements controller for this clip, which is responsible for handling mouse events on
   * the clip canvas and updating the active measurement state accordingly.
   */
  measurementsController: MeasurementsController;

  /** The index of the currently visible clip frame. Resetting this to undefined causes a redraw. */
  currentlyVisibleFrame: Ref<number | undefined>;

  /**
   * Whether a loading spinner should be shown. This occurs when the render has spent more than
   * 500ms waiting for the next frame to be available.
   */
  isLoadingSpinnerVisible: Ref<boolean>;

  /** Draws the current frame to the canvas and calls the measurements renderer if needed. */
  drawCurrentFrame: () => void;

  /** Actions to be triggered when a canvas mousedown event is received. */
  onCanvasMouseDown: (event: MouseEvent) => void;

  /** Actions to be triggered when a canvas mousemove event is received. */
  onCanvasMouseMove: (event: MouseEvent) => void;

  /** Actions to be triggered when a canvas contextmenu event is received. */
  onCanvasContextMenu: (event: MouseEvent) => void;

  /** Actions to be triggered when the canvas container receives a mouseup event. */
  onCanvasContainerMouseUp: (event: MouseEvent) => void;

  /** Resets the brightness and contrast adjustments to 1. */
  onBrightnessContrastReset: () => void;

  /** Actions to be triggered when the clip renderer is unmounted and should be cleaned up. */
  onUnmount: () => void;
}

export function create2DClipRenderer(args: {
  study: Study;
  model: ClipModel;
  canvas: HTMLCanvasElement;
  measurementCanvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  measurementCtx: CanvasRenderingContext2D;
  showMeasurements: ComputedRef<boolean>;
  togglePlayPause: () => void;
}): ClipRenderer2D {
  const {
    study,
    model,
    canvas,
    measurementCanvas,
    ctx,
    measurementCtx,
    showMeasurements,
    togglePlayPause,
  } = args;

  const currentlyVisibleFrame = ref<number | undefined>(undefined);

  const measurementsController = createMeasurementsController({
    study,
    model,
    canvas: measurementCanvas,
    currentlyVisibleFrame,
  });

  const measurementsRenderer = createMeasurementsRenderer({
    study,
    model,
    canvas: measurementCanvas,
    ctx: measurementCtx,
    showMeasurements,
  });

  const contrast = ref(1);
  const brightness = ref(1);
  const isBrightnessContrastTextVisible = computed(
    () => contrast.value !== 1 || brightness.value !== 1
  );

  function onBrightnessContrastReset(): void {
    contrast.value = 1;
    brightness.value = 1;
  }

  const previousColorMap = ref<ColorMap | undefined>(undefined);

  let canvasMouseDownPosition = [-1, -1];
  let canvasMouseDownInitialContrast = -1;
  let canvasMouseDownInitialBrightness = -1;

  // Whether the clip viewer should show a loading spinner. The loading spinner is shown when the
  // next frame to be rendered is not loaded, and more than 500ms has elapsed waiting for it to be
  // available.
  const isLoadingSpinnerVisible = ref(false);
  const showLoadingSpinnerDebouncer = useTimeoutFn(
    () => {
      isLoadingSpinnerVisible.value = true;

      // If the loading spinner is now visible and there's no currently visible frame then draw a
      // black rect to wipe any image from a previous clip model that may still be sitting in the
      // canvas
      if (currentlyVisibleFrame.value === undefined) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        measurementCtx.clearRect(0, 0, measurementCanvas.width, measurementCanvas.height);
      }
    },
    500,
    { immediate: false }
  );

  function drawCurrentFrame(): void {
    const currentFrame = model.getCurrentFrame();

    // If the current frame is already drawn then there's nothing to do
    if (
      currentFrame === currentlyVisibleFrame.value &&
      model.colorMap.value === previousColorMap.value
    ) {
      return;
    }

    const currentFrameImage = model.loadedFrames[currentFrame];
    if (currentFrameImage === undefined) {
      // If the desired frame is not loaded then start the timer for showing of the loading
      // indicator if it isn't already pending
      if (!showLoadingSpinnerDebouncer.isPending.value) {
        showLoadingSpinnerDebouncer.start();
      }

      model.isPlaying.value = false;

      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    measurementCtx.clearRect(0, 0, measurementCanvas.width, measurementCanvas.height);

    // This check is here because adding the filter every frame causes a significant performance
    // hit in Playwright tests, even though the filter won't do anything when grey
    if (model.colorMap.value !== ColorMap.Grey) {
      ctx.filter = `url(#filter-${model.clip?.id})`;
    }

    ctx.drawImage(currentFrameImage, 0, 0, canvas.width, canvas.height);

    currentlyVisibleFrame.value = currentFrame;

    measurementsRenderer.drawAllMeasurements(currentlyVisibleFrame.value);

    // If the active measurement is on this clip then notify it about the frame change
    if (activeMeasurement.value.studyClipId === model.clip?.id) {
      activeMeasurement.value.onFrameChange(currentFrame);
    }

    // Clips are set to not auto-play in Playwright, so we don't want to start playing the clip
    // as we draw each frame. In playwright, we only want to play when the play button has been
    // explicitly clicked
    if (showLoadingSpinnerDebouncer.isPending.value && !isPlaywrightTest() && !isMeasuring.value) {
      model.isPlaying.value = true;
    }

    showLoadingSpinnerDebouncer.stop();
    isLoadingSpinnerVisible.value = false;
    previousColorMap.value = model.colorMap.value;
  }

  function onCanvasMouseDown(event: MouseEvent): void {
    if (
      model.isScrubbing.value ||
      model.clip === undefined ||
      (model.clip.id !== activeMeasurement.value.studyClipId &&
        activeMeasurement.value.editingMeasurementBatchId.value !== null)
    ) {
      return;
    }

    canvasMouseDownPosition = [event.clientX, event.clientY];

    if (isMeasuring.value && model.isPlaying.value) {
      model.isPlaying.value = false;
    }

    // Only allow contrast/brightness adjustment when not measuring
    if (!isMeasuring.value) {
      canvasMouseDownInitialContrast = contrast.value;
      canvasMouseDownInitialBrightness = brightness.value;
    }

    measurementsController.handleCanvasMouseDown(event);
  }

  function onCanvasMouseMove(event: MouseEvent): void {
    const didSwallowEvent = measurementsController.handleCanvasMouseMove(event);
    if (didSwallowEvent) {
      return;
    }

    // Click and drag changes brightness/contrast if active measurement didn't consume the event
    if (event.buttons === 1 && canvasMouseDownInitialContrast !== -1) {
      contrast.value =
        canvasMouseDownInitialContrast + (canvasMouseDownPosition[0] - event.clientX) * 0.005;
      contrast.value = Math.min(contrast.value, 3.0);
      contrast.value = Math.max(contrast.value, 0.2);

      brightness.value =
        canvasMouseDownInitialBrightness + (canvasMouseDownPosition[1] - event.clientY) * 0.005;
      brightness.value = Math.min(brightness.value, 3.0);
      brightness.value = Math.max(brightness.value, 0.2);
    }
  }

  function onCanvasContainerMouseUp(event: MouseEvent): void {
    measurementsController.handleCanvasContainerMouseUp();

    // If the mouseup is close to the mousedown point then toggle play/pause
    if (
      !isMeasuring.value &&
      Math.abs(event.clientX - canvasMouseDownPosition[0]) <= 3 &&
      Math.abs(event.clientY - canvasMouseDownPosition[1]) <= 3
    ) {
      togglePlayPause();
    }
  }

  function onCanvasContextMenu(event: MouseEvent): void {
    measurementsController.handleCanvasContextMenu(event);
  }

  function onActiveMeasurementToolRequestRedraw(): void {
    if (activeMeasurement.value.studyClipId === model.clip?.id) {
      currentlyVisibleFrame.value = undefined;
    }
  }

  function onFrameChange(frameNumber: number): void {
    if (activeMeasurement.value.studyClipId !== model.clip?.id) {
      return;
    }

    let frame = frameNumber;

    // Wrap around inside the current beat if one is selected
    const heartbeat = model.getSelectedHeartbeat();
    if (heartbeat !== undefined) {
      const heartbeatLength = heartbeat.lastFrame - heartbeat.firstFrame;
      frame =
        positiveModulo(frameNumber - heartbeat.firstFrame, heartbeatLength) + heartbeat.firstFrame;
    }

    model.setCurrentFrame(frame);
  }

  // The renderer is created when the clip viewer mounts so add the event listeners here
  activeMeasurement.value.requestRedrawHandlers.add(onActiveMeasurementToolRequestRedraw);
  activeMeasurement.value.frameChangeHandlers.add(onFrameChange);

  const rafFn = useRafFn(drawCurrentFrame);

  // Redraw current frame with the contour points when the video is paused
  const unwatchRedrawContourPoints = watch(
    () => model.isPlaying,
    () => {
      if (!model.isPlaying.value) {
        currentlyVisibleFrame.value = undefined;
      }
    }
  );

  // Redraw when there are any changes to the study's measurements
  const unwatchMeasuringState = watch(
    () => [model.soloMeasurementValueId.value, showMeasurements.value, isMeasuring.value],
    () => (currentlyVisibleFrame.value = undefined)
  );

  const unwatchStudyMeasurements = watch(
    () => [study.measurements],
    () => (currentlyVisibleFrame.value = undefined),
    { deep: true }
  );

  const unwatchActiveMeasurement = watch(activeMeasurement, () => {
    activeMeasurement.value.requestRedrawHandlers.add(onActiveMeasurementToolRequestRedraw);
    activeMeasurement.value.frameChangeHandlers.add(onFrameChange);

    if (activeMeasurement.value.studyClipId === model.clip?.id) {
      if (currentlyVisibleFrame.value !== undefined) {
        activeMeasurement.value.onFrameChange(currentlyVisibleFrame.value);
      }

      currentlyVisibleFrame.value = undefined;
    }
  });

  function onUnmount(): void {
    rafFn.pause();
    showLoadingSpinnerDebouncer.stop();
    unwatchRedrawContourPoints();
    unwatchActiveMeasurement();
    unwatchMeasuringState();
    unwatchStudyMeasurements();

    activeMeasurement.value.requestRedrawHandlers.delete(onActiveMeasurementToolRequestRedraw);
    activeMeasurement.value.frameChangeHandlers.delete(onFrameChange);
  }

  return {
    brightness,
    contrast,
    isBrightnessContrastTextVisible,
    measurementsRenderer,
    measurementsController,
    currentlyVisibleFrame,
    isLoadingSpinnerVisible,
    drawCurrentFrame,
    onCanvasMouseDown,
    onCanvasMouseMove,
    onCanvasContextMenu,
    onCanvasContainerMouseUp,
    onBrightnessContrastReset,
    onUnmount,
  };
}
