<template>
  <div class="back-btn" @click="router.push({ name: 'settings-access-users-all' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to Users
  </div>

  <div class="settings-title">
    User: {{ user.name.trim().length !== 0 ? user.name : user.email }}
    <div style="font-size: 0.6em; font-weight: normal">
      Last seen
      {{ formatRelativeTime(user.lastSeenAt) }}
    </div>

    <a
      v-if="user.disabledAt === null && !isCurrentUser && user.inviteExpiresAt === null"
      class="disable-user"
      data-testid="disable-user-btn"
      @click="toggleUserEnabled"
    >
      Disable user
    </a>
  </div>

  <div v-if="user.inviteExpiresAt !== null" class="field alert-banner">
    <div style="display: flex; gap: 8px; align-items: center">
      <FontAwesomeIcon icon="circle-info" />

      <b style="margin-right: auto">
        <template v-if="isInviteExpired"> This user invitation has expired </template>
        <template v-else>This user has not yet accepted their invitation </template>
      </b>

      <button v-if="!isInviteExpired" @click="cancelInvite()">Cancel Invite</button>
      <button :disabled="!hasUserInvitePermission" class="accented" @click="resendInvite()">
        Resend Invite
      </button>
    </div>
  </div>

  <div
    v-if="isLocked"
    class="field alert-banner account-locked"
    data-testid="account-locked-banner"
  >
    <div class="banner-content">
      <b>
        This account has been locked due to too many failed login attempts. It will automatically
        unlock {{ formatRelativeTime(user.lockedUntil) }}.
      </b>
    </div>
  </div>

  <div
    v-if="user.disabledAt !== null"
    class="field alert-banner account-locked"
    data-testid="account-disabled-banner"
  >
    <div class="banner-content">
      <b>
        This user account was disabled on
        {{ formatDateTime(user.disabledAt, { includeTime: true }) }}
      </b>

      <button data-testid="enable-user-btn" @click="toggleUserEnabled()">Enable</button>
    </div>
  </div>

  <div class="field">
    <strong>Name</strong>
    <div style="display: flex; justify-content: space-between">
      <input
        v-model="user.name"
        type="text"
        :disabled="!hasUserUpdatePermission"
        data-testid="username"
        @update:model-value="updateUserDebounced"
      />
    </div>
  </div>

  <div class="field">
    <strong>Email</strong>

    <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
      {{ user.email }}
    </div>
  </div>

  <div class="field">
    <strong>Roles</strong>
    <UserRolesToggles
      v-model="user.roleIds"
      :enable-admin-toggle="!isCurrentUser"
      :enabled="hasUserRolesUpdatePermission"
      @update:model-value="updateRoles"
    />
  </div>

  <ToggleSwitch
    v-model="user.onlyAssignedStudies"
    :enabled="hasUserUpdatePermission"
    @update:model-value="saveUserPermissions"
  >
    Restrict this user to only seeing studies assigned to them
  </ToggleSwitch>

  <div v-if="currentTenant.isApiKeyAccessEnabled" class="field">
    <div style="display: flex; gap: 16px; align-items: center">
      <b>API Keys Enabled</b>
      <ToggleSwitch
        v-model="user.isApiKeyAccessEnabled"
        :enabled="hasUserUpdatePermission"
        @update:model-value="saveUserPermissions"
      />
    </div>

    <p>
      Whether this user can create and use API keys in order to access patient and study data
      programmatically.
    </p>

    <p v-if="user?.apiKeyCount !== 0">
      This user has created {{ user.apiKeyCount }} API key{{ user.apiKeyCount === 1 ? "" : "s" }}.
      Last used: {{ formatRelativeTime(user.apiKeyLastUsedAt) }}.
    </p>
  </div>

  <div class="field">
    <strong>Automatic Study Assignment</strong>
    <p>
      When a DICOM study is received, if one of the names below matches exactly to its
      <i>"PerformingPhysicianName"</i> or <i>"OperatorsName"</i> then this user will be
      automatically assigned to that study. Specify multiple names by separating them with a
      semicolon.
    </p>

    <input
      v-model="dicomPerformingPhysicianNames"
      type="text"
      placeholder="Enter DICOM names here"
      :disabled="!hasUserUpdatePermission"
      @update:model-value="updateUserDebounced"
    />
  </div>

  <div class="field">
    <strong>Account Setup</strong>

    <div class="account-setup-row">
      <div class="account-setup-box">
        <div class="icon" :class="user.hasPassword ? 'complete' : 'incomplete'">
          <FontAwesomeIcon :icon="user.hasPassword ? 'circle-check' : 'triangle-exclamation'" />
        </div>

        <div class="message">
          {{ user.hasPassword ? "Password created" : "Password not set" }}
        </div>
      </div>

      <a class="reset-link" :class="{ disabled: !hasUserUpdatePermission }" @click="resetPassword">
        Reset password
      </a>
    </div>

    <div class="account-setup-row">
      <div class="account-setup-box">
        <div class="icon" :class="user.onboarded ? 'complete' : 'incomplete'">
          <FontAwesomeIcon :icon="user.onboarded ? 'circle-check' : 'triangle-exclamation'" />
        </div>

        <div class="message">
          {{ user.onboarded ? "Onboarding complete" : "Onboarding not completed" }}
        </div>
      </div>

      <a
        v-if="user.onboarded"
        class="reset-link"
        :class="{ disabled: !hasUserUpdatePermission }"
        @click="resetOnboarding"
      >
        Reset onboarding
      </a>
    </div>

    <div class="account-setup-row">
      <div class="account-setup-box">
        <div class="icon" :class="user.mfaEnabled ? 'complete' : 'incomplete'">
          <FontAwesomeIcon :icon="user.mfaEnabled ? 'circle-check' : 'triangle-exclamation'" />
        </div>

        <div class="message">
          {{ user.mfaEnabled ? "MFA enabled" : "MFA not enabled" }}
        </div>
      </div>
    </div>

    <div class="account-setup-row">
      <div class="account-setup-box">
        <div class="icon" :class="user.hasPin ? 'complete' : 'incomplete'">
          <FontAwesomeIcon :icon="user.hasPin ? 'circle-check' : 'triangle-exclamation'" />
        </div>

        <div class="message">
          {{ user.hasPin ? "PIN created" : "PIN not set" }}
        </div>
      </div>

      <a
        v-if="user.hasPin"
        class="reset-link"
        :class="{ disabled: !hasUserUpdatePermission }"
        @click="resetPin"
      >
        Reset PIN
      </a>
    </div>
  </div>

  <ActivityOverlay v-if="activityText" :text="activityText" />
</template>

<script setup lang="ts">
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import router from "@/router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { onKeyStroke, useDebounceFn } from "@vueuse/core";
import axios, { type AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { computed, onMounted, ref } from "vue";
import { formatDateTime, formatRelativeTime } from "../../../backend/src/shared/date-time-utils";
import { toDateTime } from "../../../backend/src/studies/study-time";
import type { UserGetOneResponseDto } from "../../../backend/src/tenants/dto/user-get-one.dto";
import {
  hasUserInvitePermission,
  hasUserRolesUpdatePermission,
  hasUserUpdatePermission,
} from "../auth/authorization";
import { currentTenant, currentUser, fetchCurrentTenantAndUser } from "../auth/current-session";
import ActivityOverlay from "../components/ActivityOverlay.vue";
import { addNotification } from "../utils/notifications";
import UserRolesToggles from "./UserRolesToggles.vue";

interface Props {
  id: string;
}

const props = defineProps<Props>();

const isLoaded = ref(false);
const activityText = ref("");

const user = ref<UserGetOneResponseDto>({
  id: "",
  email: "",
  name: "",
  hasPin: false,
  hasPassword: false,
  mfaEnabled: false,
  roleIds: [],
  lastSeenAt: null,
  onlyAssignedStudies: false,
  onboarded: false,
  dicomPerformingPhysicianNames: [],
  isApiKeyAccessEnabled: false,
  apiKeyLastUsedAt: null,
  inviteExpiresAt: null,
  lockedUntil: null,
  disabledAt: null,
  isVisible: false,
});

onMounted(async () => {
  activityText.value = "Loading";

  let response: AxiosResponse<UserGetOneResponseDto> | undefined = undefined;
  try {
    response = await axios.get<UserGetOneResponseDto>(`/api/tenants/users/${props.id}`);
  } catch {
    addNotification({ type: "error", message: "Failed loading user data" });
    return;
  } finally {
    activityText.value = "";
  }

  user.value = response.data;
  isLoaded.value = true;
});

const isInviteExpired = computed(
  () =>
    user.value.inviteExpiresAt !== null &&
    DateTime.fromISO(user.value.inviteExpiresAt as string) < DateTime.now()
);

async function saveUserPermissions(): Promise<void> {
  try {
    await axios.patch(`/api/tenants/users/${user.value.id}`, {
      onlyAssignedStudies: user.value.onlyAssignedStudies,
      isApiKeyAccessEnabled: user.value.isApiKeyAccessEnabled,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating user permissions" });
    return;
  }

  addNotification({ type: "info", message: "Updated user permissions" });
}

async function toggleUserEnabled(): Promise<void> {
  const enabled = user.value.disabledAt !== null;

  try {
    await axios.patch(`/api/tenants/users/${user.value.id}`, { enabled });
  } catch {
    addNotification({
      type: "error",
      message: `Failed ${enabled ? "enabling" : "disabling"} user`,
    });
    return;
  }

  user.value.disabledAt = enabled ? null : new Date();

  addNotification({ type: "info", message: `User ${enabled ? "enabled" : "disabled"}` });
}

async function updateRoles(newRoleIds: string[]): Promise<void> {
  try {
    await axios.patch(`/api/tenants/users/${user.value.id}/roles`, { roleIds: newRoleIds });
  } catch {
    addNotification({ type: "error", message: "Failed updating user roles" });
    return;
  }

  addNotification({ type: "info", message: "Updated user roles" });

  if (user.value.id === currentUser.id) {
    await fetchCurrentTenantAndUser();
  }
}

const dicomPerformingPhysicianNames = computed({
  get() {
    return user.value.dicomPerformingPhysicianNames.join(";");
  },
  set(newValue: string) {
    user.value.dicomPerformingPhysicianNames = newValue.split(";");

    void updateUserDebounced();
  },
});

const isCurrentUser = computed(() => props.id === currentUser.id);

async function updateUser(): Promise<void> {
  try {
    await axios.patch(`/api/tenants/users/${user.value.id}`, {
      name: user.value.name,
      dicomPerformingPhysicianNames: user.value.dicomPerformingPhysicianNames,
    });
  } catch {
    addNotification({ type: "error", message: "Failed updating user" });
    return;
  }

  addNotification({ type: "info", message: "Updated user" });
}

const updateUserDebounced = useDebounceFn(() => {
  void updateUser();
}, 1000);

async function resetPassword(): Promise<void> {
  activityText.value = "Resetting password";

  try {
    await axios.post(`/api/tenants/users/${user.value.id}/reset-password`);
  } catch {
    addNotification({ type: "error", message: "Failed resetting password" });
    return;
  } finally {
    activityText.value = "";
  }

  user.value.hasPassword = false;

  addNotification({
    type: "info",
    message: "Current password deleted. This user has been sent an email to reset their password.",
  });
}

async function resetOnboarding(): Promise<void> {
  activityText.value = "Resetting onboarding";

  try {
    await axios.patch(`/api/tenants/users/${user.value.id}`, { onboarded: false });
  } catch {
    addNotification({ type: "error", message: "Failed resetting onboarding" });
    return;
  } finally {
    activityText.value = "";
  }

  user.value.onboarded = false;

  addNotification({ type: "info", message: "Reset onboarding" });
}

async function resetPin(): Promise<void> {
  activityText.value = "Resetting PIN";

  try {
    await axios.patch(`/api/tenants/users/${user.value.id}`, { pinHash: null });
  } catch {
    addNotification({ type: "error", message: "Failed resetting PIN" });
    return;
  } finally {
    activityText.value = "";
  }

  user.value.hasPin = false;

  addNotification({ type: "info", message: "PIN was reset" });
}

async function openUserList(): Promise<void> {
  await router.push({ name: "settings-access-users-all" });
}

onKeyStroke("Escape", () => void openUserList());

async function cancelInvite(): Promise<void> {
  if (!confirm(`Are you sure you want to cancel the invitation for "${user.value.email}"?`)) {
    return;
  }

  activityText.value = "Cancelling invitation";

  try {
    await axios.delete(`/api/users/${user.value.id}/invite`);
  } catch {
    addNotification({
      type: "error",
      message: `Failed cancelling invitation for ${user.value.email}`,
    });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: `Cancelled invitation for ${user.value.email}` });
  await openUserList();
}

async function resendInvite(): Promise<void> {
  activityText.value = "Resending invitation email";

  try {
    await axios.post(`/api/users`, {
      userEmail: user.value.email,
      initialRoleIds: user.value.roleIds,
      initialOnlyAssignedStudies: user.value.onlyAssignedStudies,
    });
  } catch {
    addNotification({
      type: "error",
      message: `Failed resending invitiation for ${user.value.email}`,
    });
    return;
  } finally {
    activityText.value = "";
  }

  addNotification({ type: "info", message: `Resent invitation email to ${user.value.email}` });
}

const isLocked = computed(() => {
  const lockedUntil = toDateTime(user.value.lockedUntil);

  return lockedUntil !== undefined && lockedUntil > DateTime.now();
});
</script>

<style scoped lang="scss">
.disable-user {
  font-size: 13px;
  font-weight: normal;
  text-decoration: underline;
  margin-left: auto;
}

.field {
  display: grid;
  gap: 8px;
}

.alert-banner {
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  background-color: var(--bg-color-2);
  padding: 8px 8px 8px 16px;

  &.account-locked {
    background-color: var(--notification-error-bg-color);
    border: none;
  }
}

.banner-content {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.account-setup-row {
  display: flex;
  gap: 8px;
}

.account-setup-box {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  overflow: hidden;
  background-color: var(--bg-color-2);
  font-weight: bold;
  width: max-content;
  height: max-content;

  .icon {
    padding: 8px;

    &.complete {
      color: #0fab00;
      background-color: #0d252e;
    }

    &.incomplete {
      color: var(-accent-color-hot);
      background-color: #2e1633;
    }
  }

  .message {
    padding: 8px;
  }
}

input {
  width: 300px;
}

.reset-link {
  text-decoration: underline;
  align-self: center;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
</style>
