<script setup lang="ts">
import DatePicker from "@/components/DatePicker.vue";
import DropdownWidget from "@/components/DropdownWidget.vue";
import { addNotification } from "@/utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import { computed, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { BillingTenantGetOneResponseDto } from "../../../backend/src/billing/dto/billing-tenant-get-one.dto";
import { BillingTenantUpdateOneDto } from "../../../backend/src/billing/dto/billing-tenant-update-one.dto";
import { BillingPeriod } from "../../../backend/src/billing/dto/billing-tenant.dto";
import { formatDateTime } from "../../../backend/src/shared/date-time-utils";

interface Props {
  id: string;
}

const router = useRouter();
const queryClient = useQueryClient();
const props = defineProps<Props>();
const tenantBillingDetails = reactive<BillingTenantGetOneResponseDto>({
  id: "",
  name: "",
  billingStartDate: new Date(),
  billingPeriodLength: 1,
  selectedBillingPeriod: null,
  currentBillingPeriod: null,
  quotas: {
    storage: "",
    echo: 0,
    vascular: 0,
  },
  usage: {
    echo: 0,
    vascular: 0,
    other: 0,
  },
  allBillingPeriods: [],
});

// Editable properties
const billingStartDate = ref<Date | null>(new Date());
const billingPeriodLength = ref<number>(1);
const storageQuota = ref<number>(0);
const echoQuota = ref<number>(0);
const vascularQuota = ref<number>(0);

// Billing Period Options
const selectableBillingPeriods = ref<{ text: string; value: string }[]>([]);
const selectedPeriod = ref<string>("");

const { isError, isFetched } = useQuery({
  enabled: computed(() => !!props.id),
  queryKey: ["tenant-details", props.id, selectedPeriod],
  queryFn: async ({ queryKey }) => {
    const [, id, period] = queryKey;

    const url = `/api/billings/${id}`;

    if (period === "") {
      const response = await axios.get<BillingTenantGetOneResponseDto>(url);
      return response.data;
    }

    const selectedPeriodData = tenantBillingDetails.allBillingPeriods.find(
      (p) => formatPeriodText(p) === period
    );

    if (selectedPeriodData === undefined) {
      throw new Error("Invalid period selected");
    }

    const params = new URLSearchParams({
      start: selectedPeriodData.start.toString(),
      end: selectedPeriodData.end.toString(),
    });
    const response = await axios.get<BillingTenantGetOneResponseDto>(url, {
      params,
    });
    return response.data;
  },
  select: (data) => {
    Object.assign(tenantBillingDetails, data);

    // Recalculate billing periods if data is re-fetched
    selectableBillingPeriods.value = getBillingPeriods();

    if (!selectedPeriod.value) {
      billingPeriodLength.value = tenantBillingDetails.billingPeriodLength ?? 1;
      billingStartDate.value = ensureDate(tenantBillingDetails.billingStartDate);
      storageQuota.value = bytesToTerabytes(tenantBillingDetails.quotas.storage);
      echoQuota.value = tenantBillingDetails.quotas.echo;
      vascularQuota.value = tenantBillingDetails.quotas.vascular;

      setActivePeriodToLatest();
    } else {
      // This is a period-specific data load
      tenantBillingDetails.usage = data.usage;
      tenantBillingDetails.selectedBillingPeriod = data.selectedBillingPeriod;
    }
  },
});

watch(isError, (newValue) => {
  if (newValue) {
    void addErrorNotification();
  }
});

const updateMutation = useMutation({
  mutationFn: async (updateDto: Partial<BillingTenantUpdateOneDto>) => {
    await axios.patch(`/api/billings/${props.id}`, updateDto);
  },
  onSuccess: async () => {
    await queryClient.invalidateQueries({ queryKey: ["tenant-details"] });
    addNotification({ type: "info", message: "Tenant billing details updated" });
  },
  onError: (error) => {
    addNotification({ type: "error", message: "Failed to update tenant billing details" });
    console.error("Error updating quotas:", error);
  },
});

function setActivePeriodToLatest() {
  if (selectableBillingPeriods.value.length > 0) {
    selectedPeriod.value = selectableBillingPeriods.value[0].value;
  }
}

async function updateBillingDetails(): Promise<void> {
  // Super basic validation.
  // Todo: This is a bit complex. Might be good to use zod schemas for both backend/frontend and share validation
  if (!isFetched.value) return;

  const currentData = tenantBillingDetails;
  const updateDto: Partial<BillingTenantUpdateOneDto> = {};

  const billingStartHasChanged = hasChanged(billingStartDate.value, currentData.billingStartDate);
  if (billingStartHasChanged && billingStartDate.value !== null) {
    updateDto.billingStartDate = billingStartDate.value;
  }

  const billingLengthHasChanged = hasChanged(
    billingPeriodLength.value,
    currentData.billingPeriodLength
  );
  if (billingLengthHasChanged) {
    updateDto.billingPeriodLength = billingPeriodLength.value;
  }

  const quotas: Partial<BillingTenantUpdateOneDto["quotas"]> = {};

  const newStorage = terabytesToBytes(storageQuota.value);
  if (newStorage !== currentData.quotas.storage) {
    quotas.storage = newStorage;
  }

  if (hasChanged(echoQuota.value, currentData.quotas.echo)) {
    quotas.echo = echoQuota.value;
  }

  if (hasChanged(vascularQuota.value, currentData.quotas.vascular)) {
    quotas.vascular = vascularQuota.value;
  }

  if (Object.keys(quotas).length > 0) {
    updateDto.quotas = quotas;
  }

  if (Object.keys(updateDto).length === 0) {
    return;
  }

  await updateMutation.mutateAsync(updateDto);

  // Check if billing period length or start date changed
  if (billingStartHasChanged || billingLengthHasChanged) {
    setActivePeriodToLatest();
  }
}

function hasChanged<T>(
  newValue: T | null | undefined,
  currentValue: T | null | undefined
): boolean {
  if (newValue === null && currentValue === null) return false;
  if (newValue === null || currentValue === null) return true;

  if (newValue instanceof Date && currentValue instanceof Date) {
    // Compare dates ignoring milliseconds
    return Math.floor(newValue.getTime() / 1000) !== Math.floor(currentValue.getTime() / 1000);
  }
  return newValue !== currentValue;
}

function getBillingPeriods() {
  const billingPeriods = tenantBillingDetails.allBillingPeriods
    .sort((a, b) => {
      return new Date(b.start).getTime() - new Date(a.start).getTime();
    })
    .map((period) => ({
      text: formatPeriodText(period),
      value: formatPeriodText(period),
    }));

  if (billingPeriods.length > 0) {
    billingPeriods[0].text += " (Current)";
  }

  return billingPeriods;
}

function ensureDate(date: Date | null): Date {
  if (date === null) return new Date();
  return new Date(date);
}

const saveDebounced = useDebounceFn(() => {
  void updateBillingDetails();
}, 1000);

function addErrorNotification() {
  return addNotification({
    type: "error",
    message: "Failed loading tenant data",
  });
}

function formatPeriodText(period: BillingPeriod) {
  return `${formatDateTime(period.start)} - ${formatDateTime(period.end)}`;
}

function bytesToTerabytes(bytesString: string) {
  const bytes = parseFloat(bytesString);
  return isNaN(bytes) ? 0 : bytes / 1024 / 1024 / 1024 / 1024;
}

function terabytesToBytes(terabytes: number): string {
  return (terabytes * 1024 * 1024 * 1024 * 1024).toString();
}
</script>

<template>
  <div class="back-btn" @click="router.push({ name: 'settings-billing-all' })">
    <FontAwesomeIcon icon="arrow-left" />
    Back to Tenants
  </div>

  <div class="settings-title">Tenant: {{ tenantBillingDetails.name }}</div>

  <div>
    <DropdownWidget
      v-model="selectedPeriod"
      placeholder="Select Period"
      :items="selectableBillingPeriods"
      class="dropdown-widget"
    />
  </div>

  <div class="usage-info">
    <div class="field">
      <strong>Echocardiography Studies</strong>
      <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
        {{ tenantBillingDetails.usage.echo }} / {{ echoQuota }}
      </div>
    </div>

    <div class="field">
      <strong>Vascular Studies</strong>
      <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
        {{ tenantBillingDetails.usage.vascular }} / {{ vascularQuota }}
      </div>
    </div>

    <div class="field">
      <strong>Other Studies (unbilled)</strong>
      <div class="selectable-text" style="display: flex; align-items: center; gap: 16px">
        {{ tenantBillingDetails.usage.other }}
      </div>
    </div>
  </div>

  <div class="divider"></div>

  <div class="field">
    <strong>Storage Quota</strong>
    <p>The amount of storage this tenant has subscribed for (in terabytes)</p>
    <div>
      <input
        v-model="storageQuota"
        type="number"
        step="0.1"
        min="0"
        @update:model-value="saveDebounced"
      />
    </div>
  </div>

  <div class="field">
    <strong>Echo Quota</strong>
    <p>The minimum number of echocardiograms this tenant will be billed for per quota period</p>
    <div>
      <input v-model="echoQuota" type="number" @update:model-value="saveDebounced" />
    </div>
  </div>

  <div class="field">
    <strong>Vascular Quota</strong>
    <p>The minimum number of vascular studies this tenant will be billed for per quota period</p>
    <div>
      <input v-model="vascularQuota" type="number" @update:model-value="saveDebounced" />
    </div>
  </div>

  <div class="field">
    <strong>Billing Start Date</strong>
    <p>The date this tenant will start to be billed from</p>
    <DatePicker
      v-if="isFetched"
      v-model="billingStartDate"
      mode="date"
      class="dropdown-widget"
      @update:model-value="saveDebounced"
    />
    <p v-if="billingStartDate === null" style="color: red">Billing start date shouldn't be empty</p>
  </div>
  <div class="field">
    <strong>Billing Period Length</strong>
    <p>The duration of each billing period in months</p>
    <div class="selectable-text">
      <input
        v-model="billingPeriodLength"
        type="number"
        min="1"
        @update:model-value="saveDebounced"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.usage-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dropdown-widget {
  width: 15rem;
}

.divider {
  align-self: stretch;
  height: 0;
  border-top: 1px solid var(--border-color-1);
  margin-top: 6px;
  margin-bottom: 6px;
}

input {
  width: 14rem;
}
</style>
