<template>
  <BaseClipViewer
    :canvas-rect="canvasRect"
    :study="study"
    :grid-item="gridItem"
    :canvas-height="canvasElement?.height"
    :canvas-width="canvasElement?.width"
    :clip-aspect-ratio="gridItem.clip ? gridItem.clip.width! / gridItem.clip.height! : 1"
    @update:canvas-rect="updateCanvasRect"
    @set-canvas-dimensions="setCanvasDimensions"
    @step-frame="onStepFrame"
    @scrub="onScrub"
  >
    <template #canvases>
      <canvas
        ref="webglCanvasElement"
        data-testid="webgl-canvas"
        style="z-index: 1"
        :style="{
          top: `${canvasRect.top.toFixed(0)}px`,
          left: `${canvasRect.left.toFixed(0)}px`,
          width: `${canvasRect.width.toFixed(0)}px`,
          height: `${canvasRect.height.toFixed(0)}px`,
        }"
      />

      <canvas
        ref="canvasElement"
        data-testid="canvas"
        style="z-index: 2"
        :style="{
          top: `${canvasRect.top.toFixed(0)}px`,
          left: `${canvasRect.left.toFixed(0)}px`,
          width: `${canvasRect.width.toFixed(0)}px`,
          height: `${canvasRect.height.toFixed(0)}px`,
        }"
        @mousedown="onCanvasMouseDown"
        @mousemove="onCanvasMouseMove"
      />
    </template>

    <template v-if="gridItem.series.nrrdProcessState === NRRDProcessState.Completed" #imageControls>
      <DropdownWidget
        class="slice-direction-dropdown"
        :class="{ disabled: windowingControlDisabled }"
        data-testid="slice-direction-dropdown"
        :model-value="gridItem.sliceDirection.value"
        :items="[
          { value: CTSliceDirection.Axial, text: 'Axial' },
          { value: CTSliceDirection.Coronal, text: 'Coronal' },
          { value: CTSliceDirection.Sagittal, text: 'Sagittal' },
        ]"
        :disabled="windowingControlDisabled"
        @update:model-value="updateSliceDirection"
      />

      <div
        v-if="crosshairsStore.state === CrosshairsStoreState.Active"
        class="crosshair-color-indicator"
        :style="{ 'background-color': getColorForSliceDirection(gridItem.sliceDirection.value) }"
      />
    </template>
  </BaseClipViewer>
</template>

<script setup lang="ts">
import { useEventListener, useRafFn } from "@vueuse/core";
import { clamp } from "lodash";
import { WebGLRenderer } from "three";
import { computed, onMounted, ref, watch } from "vue";
import { NRRDProcessState } from "../../../../backend/src/studies/study-clip-processed-files";
import DropdownWidget from "../../components/DropdownWidget.vue";
import { Study } from "../../utils/study-data";
import { crosshairsStore, CrosshairsStoreState } from "../ct/ct-crosshairs";
import { ctSettings } from "../ct/ct-settings";
import {
  createCTCrosshairsRenderer,
  CTCrosshairsRenderer,
  getColorForSliceDirection,
} from "./../ct/ct-crosshairs-renderer";
import { CTSliceDirection } from "./../ct/ct-model";
import BaseClipViewer from "./BaseClipViewer.vue";
import { CanvasContainerRect } from "./clip-renderer-2d";
import { CTClipsGridItem } from "./clips-grid-item";

interface Props {
  study: Study;
  gridItem: CTClipsGridItem;
}

const props = defineProps<Props>();

const canvasElement = ref<HTMLCanvasElement | null>(null);
const webglCanvasElement = ref<HTMLCanvasElement | null>(null);

let webglRenderer: WebGLRenderer | null = null;
let crosshairsRenderer: CTCrosshairsRenderer | null = null;

const canvasRect = ref({ top: 0, left: 0, width: 0, height: 0 });

function setupRenderer(): void {
  if (webglCanvasElement.value) {
    if (webglRenderer === null) {
      webglRenderer = new WebGLRenderer({ canvas: webglCanvasElement.value });
    }

    props.gridItem.provideRenderer(webglRenderer, canvasRect.value);
  }
}

function updateCanvasRect(rect: CanvasContainerRect): void {
  // Shift the canvas off the top by 32px when in CT viewers so the controls don't overlap any clinical
  // content. The same distance is taken off the width to preserve the canvas aspect ratio.
  canvasRect.value = {
    top: rect.top + 32,
    left: rect.left + 16,
    width: rect.width - 32,
    height: rect.height - 32,
  };
}

function setCanvasDimensions(dims: { width: number; height: number }): void {
  if (canvasElement.value === null) {
    return;
  }

  canvasElement.value.width = dims.width;
  canvasElement.value.height = dims.height;
}

function updateSliceDirection(newSliceDirection: string): void {
  const gridItem = props.gridItem;
  gridItem.sliceDirection.value = newSliceDirection as CTSliceDirection;
}

function onStepFrame(delta: number): void {
  const gridItem = props.gridItem;
  const newSliceNumber = (gridItem.sliceNumber.value + delta) % gridItem.maxSliceNumber.value;

  gridItem.sliceNumber.value =
    newSliceNumber < 0 ? newSliceNumber + gridItem.maxSliceNumber.value : newSliceNumber;
}

function onScrub(xFraction: number): void {
  const gridItem = props.gridItem;
  gridItem.sliceNumber.value = Math.floor(xFraction * gridItem.maxSliceNumber.value);
}

let canvasMouseDownPosition = [-1, -1];
let canvasMouseDownInitialWindowLevel = -1;
let canvasMouseDownInitialWindowWidth = -1;

function onCanvasMouseDown(event: MouseEvent): void {
  const didSwallowEvent = crosshairsRenderer?.onCanvasMouseDown(event);

  if (didSwallowEvent === true) {
    return;
  }

  canvasMouseDownPosition = [event.clientX, event.clientY];
  canvasMouseDownInitialWindowLevel = ctSettings.windowLevel.value;
  canvasMouseDownInitialWindowWidth = ctSettings.windowWidth.value;
}

function onCanvasMouseMove(event: MouseEvent): void {
  const didSwallowEvent = crosshairsRenderer?.onCanvasMouseMove(event);

  if (didSwallowEvent !== true && event.buttons === 1 && canvasMouseDownInitialWindowLevel !== -1) {
    const newWindowLevel =
      canvasMouseDownInitialWindowLevel + (canvasMouseDownPosition[1] - event.clientY);
    ctSettings.windowLevel.value = clamp(newWindowLevel, -1024, 4096);

    const newWindowWidth =
      canvasMouseDownInitialWindowWidth - (canvasMouseDownPosition[0] - event.clientX);
    ctSettings.windowWidth.value = clamp(newWindowWidth, 0, 2048);
  }
}

// Stop any windowing adjustment when a mouseup event occurs
useEventListener(document, "mouseup", () => {
  crosshairsRenderer?.onCanvasMouseUp();

  canvasMouseDownInitialWindowLevel = -1;
});

onMounted(() => {
  setupRenderer();
});

watch(
  () => props.gridItem,
  () => {
    setupRenderer();
  }
);

//
// Crosshairs
//

const windowingControlDisabled = computed(
  () =>
    crosshairsStore.value.state === CrosshairsStoreState.Active &&
    crosshairsStore.value.models[props.gridItem.sliceDirection.value].series.id ===
      props.gridItem.series.id
);

// When the overall store state changes, either spin up or tear down the crosshairs renderer
watch(crosshairsStore, () => {
  if (
    crosshairsStore.value.state === CrosshairsStoreState.Active &&
    crosshairsStore.value.series.id === props.gridItem.series.id
  ) {
    const canvas = canvasElement.value;
    const ctx = canvas?.getContext("2d") ?? null;

    if (canvas === null || ctx === null) {
      return;
    }

    crosshairsRenderer = createCTCrosshairsRenderer({
      model: props.gridItem,
      canvas,
      ctx,
    });
  } else {
    crosshairsRenderer = null;
  }
});

useRafFn(() => {
  if (crosshairsRenderer !== null) {
    crosshairsRenderer.render();
  }
});
</script>

<style scoped lang="scss">
canvas {
  position: absolute;
}

.ct-mode-wrapper {
  grid-area: 1 / 1;
}

.ct-mode-btn {
  display: flex;
  z-index: 3;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--accent-color-1);
  background-color: var(--bg-color-2);
  border-radius: var(--border-radius);
  border: 1px solid var(--accent-color-1);
  height: 12px;
  width: 60px;
  padding: 4px 8px;
  margin: 8px 8px 8px auto;

  &.processing {
    width: 80px;
  }

  &:hover,
  &.selected {
    color: var(--accent-color-2);
    background-color: var(--bg-color-3);
  }

  &.selected {
    border: 1px solid var(--accent-color-2);
  }
}

.windowing-block {
  display: flex;
  gap: 1px;
  background-color: var(--border-color-1);
  border: 1px solid var(--border-color-1);
  border-radius: var(--border-radius);
  overflow: hidden;

  .windowing-item {
    display: flex;
    background-color: var(--bg-color-2);
    padding: 0px 4px;
    align-items: center;

    &:not(input) {
      cursor: default;
    }
  }
}

.windowing-input {
  height: 20px;
  width: 30px;
  padding: 0px 4px;
  border: 0;
  text-align: right;
  cursor: text;

  &:hover,
  &:focus {
    background-color: var(--bg-color-2);
  }
}

.preset-item {
  &:hover {
    color: var(--accent-color-2);
  }
}

.slice-direction-dropdown {
  height: 22px;

  :deep(select) {
    line-height: 14px;
    height: 14px;
  }
}

.crosshair-color-indicator {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid var(--border-color-1);
}
</style>
../ct/ct-settings
